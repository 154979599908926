import { useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import PersonImage from '../../../Assets/img/user.png'
import axios from 'axios'

export default function StoreCard(props) {
    const [showMenu, setShowMenu] = useState(false)
    const [showUpgradePlan, setShowUpgradePlan] = useState(false)
    const [planList, setPlanList] = useState([])
    const url = process.env.REACT_APP_BASEURL

    const upgradePlan = async (id) => {
        if (id === props.id)
            console.log('Same Plan')
        else {
            axios.post(url + 'RequestUpgrade', {
                oldplan: props.planid,
                newplan: id,
                statusid: 0,
                storeid: props.id,
                userid: sessionStorage.getItem('sgid'),
                duration: 1,
                requestedby: sessionStorage.getItem('sgid'),
            })
                .then(async (response) => {
                    response = response.data
                    if (response.result) {
                        await getPlans()
                        await setShowUpgradePlan(false)
                        await setShowMenu(false);
                    }
                })
        }
    }

    const getPlans = async () => {
        axios.get(url + 'GetActivePlans')
            .then(async (response) => {
                response = response.data
                if (response.result) {
                    await setShowMenu(!showMenu);
                    await setPlanList(response.data)
                    await setShowUpgradePlan(!showUpgradePlan)
                }
                else {
                    console.log(response.err)
                }
            })
    }

    return (
        <div className='flex flex-col w-full relative h-56 rounded bg-primary shadow drop-shadow shadow-primary mb-1 lg:mb-5 z-1 relative'>
            {
                showUpgradePlan
                    ? <div className='fixed z-10'>
                        <div className='fixed bg-secondary shadow drop-shadow flex flex-col justify-center items-center z-10'>
                            {
                                planList
                                    ? planList.map((d, index) => {
                                        return <div key={index} className={'p-1 px-5 cursor-pointer hover:bg-red w-full ' + (d.name === props.plan ? 'bg-red' : '')} onClick={() => upgradePlan(d.id)}>{d.name}</div>
                                    })
                                    : null
                            }
                        </div>
                        <div className='fixed w-full h-full bg-white inset-0 opacity-20 z-1' onClick={() => setShowUpgradePlan(!showUpgradePlan)}></div>
                    </div>
                    : null
            }
            <div className='flex relative z-1'>
                {
                    showMenu
                        ? <div className='fixed w-full h-full'>
                            <div className='fixed bg-secondary shadow drop-shadow flex flex-col right-2 top-10 z-10'>
                                <span className='p-1 px-4 hover:bg-primary cursor-pointer' onClick={() => { }}>Inactive</span>
                                <span className='p-1 px-4 hover:bg-primary cursor-pointer' onClick={() => { getPlans() }}>Upgrade Plan</span>
                                <span className='p-1 px-4 hover:bg-primary cursor-pointer' onClick={() => { }}>Block</span>
                            </div>
                            <div className='fixed w-full h-full inset-0 opacity-20 z-1' onClick={() => setShowMenu(!showMenu)}></div>
                        </div>
                        : null
                }
                <BiDotsVerticalRounded size={30} className='absolute right-0 m-2 hover:bg-color1 p-1 rounded cursor-pointer' onClick={() => setShowMenu(!showMenu)} />
                <div className='flex flex-row flex-grow p-3 justify-center '>
                    <div className='flex p-auto mt-5'>
                        <img src={props.image ? props.image : PersonImage} alt='' className='rounded-full w-28 h-28' />
                    </div>
                </div>
            </div>
            <div className='flex flex-col p-5 justify-start'>
                <div className='flex flex-row text-md'>
                    <div className='flex justify-start w-full'>
                        {props.name}
                    </div>
                    <div className='flex font-bold justify-end w-full italic'>
                        {props.plan}
                    </div>
                </div>
            </div>
        </div>
    )
}