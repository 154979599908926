import PlanCard from "./PlanCard"
import PlanNew from "./PlanNew"
import { useState, useEffect } from "react"
import axios from "axios"
import { useGlobalState } from "../../../Globals/GlobalStates"

export default function PlanView(props) {
    const url = process.env.REACT_APP_BASEURL
    const [planList, setPlanList] = useState([])
    const [refresh, setRefresh] = useGlobalState('refreshPlan')

    const getPlans = async () => {
        await axios.get(url + 'GetActivePlans')
            .then((response) => {
                response = response.data
                if (response.result) {
                    setPlanList(response.data)
                }
            })
            .catch
    }

    useEffect(() => {
        if (refresh) {
            setRefresh(false)
            getPlans()
        }

    }, [refresh])

    useEffect(() => {
        setRefresh(true)
    }, [])

    return (
        <div className=''>
            <div className="justify-end m-1">
                <PlanNew />
            </div>

            <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 justify-between pt-2 w-full'>
                {
                    planList
                        ? planList.map((d, index) => {
                            return <PlanCard key={index} name={d.name} price={d.price} user="1" account={d.accounting} pos={d.pos} employee='y' loyality={d.loyality} />
                        })
                        : <span>No data to show</span>
                }
                {/* <PlanCard name='Free' price='19$' user="5" account='y' pos='n' employee='y' loyality='n' /> */}
            </div>
        </div>
    )
}