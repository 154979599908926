import BackButton from "../../Admin/Controls/BackButton"
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdAddAPhoto } from 'react-icons/md'
import { BiPlus, BiMinus } from 'react-icons/bi'
import { useState } from "react"
import Toggle from '../../utils/Toggle'

export default function ServiceNew(props) {
    // const handleDscountPercentage = (e) => {
    //     setErrDiscountPer('')
    //     let x = e.target.value
    //     if (x > 100 || x < 0)
    //         setErrDiscountPer('Number must be between 0 and 100')
    // }

    return (
        <div className='flex flex-col w-full lg:px-[10%] px-0'>
            <div className='flex flex-row items-center py-2'>
                <BackButton />
                <div className="italic text-xl text-color8">
                    Add Service
                </div>
            </div>
            <div className="grid lg:grid-cols-2 grid-row lg:gap-3 gap-5 bg-primary lg:p-4">
                <div className="flex flex-col px-4 shadow drop-shadow shadow-color5 py-2">
                    <span className="absolute w-fit bg-primary text-xl text-color5 px-2 italic -top-4 right-6 font-bold">Basic Info</span>
                    <div className="flex flex-col w-full">
                        <span className="py-2 px-1 text-plant">Add Photo to your service</span>
                        <div className="flex w-40 justify-center items-center w-full bg-secondary text-primary h-36 cursor-pointer rounded-xl">
                            <input type={'file'} className="hidden" />
                            <span className="text-plant text-sm bg-secondary p-2 rounded"><MdAddAPhoto size={25} /></span>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                        <span className="text-plant text-sm absolute left-3 z-10">Service Name</span>
                        <input className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a Service Name" />
                    </div>
                    <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                        <span className="text-plant text-sm absolute left-3 z-10">Service Category</span>
                        <input className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Choose a Brand" readOnly />
                        <IoMdArrowDropdown className="absolute inset-y-0 right-5 translate-y-1/2 flex items-center cursor-pointer text-plant" size={33} />
                    </div>
                    <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                        <span className="text-plant text-sm absolute left-3 z-10">Service available for</span>
                        <input className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Choose a Brand" readOnly />
                        <IoMdArrowDropdown className="absolute inset-y-0 right-5 translate-y-1/2 flex items-center cursor-pointer text-plant" size={33} />
                    </div>
                    <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                        <span className="text-plant text-sm absolute left-3 z-10">Service Description</span>
                        <textarea className="w-full h-[82px] bg-secondary focus:bg-black outline-none py-1 pt-5 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a Description" rows={3} style={{ resize: "none" }} />
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-col px-4 shadow drop-shadow shadow-color5 py-2">
                        <span className="absolute w-fit bg-primary text-xl text-color5 px-2 italic -top-4 right-6 font-bold">Team</span>
                        <div className="flex flex-col justify-between py-1 mt-6 bg-secondary rounded-xl px-6">
                            <span className="text-plant text-sm z-10">Assign team member to the service</span>
                            <div className="">
                                <div className="flex w-full py-2">
                                    <div className="flex items-center px-2">
                                        <input id="checkall" type={'checkbox'} onClick={(e) => console.log(e.target.checked)} />
                                    </div>
                                    <div className="flex items-center" htmlFor='checkall'> Select All</div>
                                </div>
                                <div className="flex w-full py-2">
                                    <div className="flex items-center px-2">
                                        <input type={'checkbox'} />
                                    </div>
                                    <div className="w-16 h-16 flex justify-center px-6 items-center text-2xl font-bold rounded-[50%] bg-plant">E1</div>
                                    <div className="flex items-center px-6">Employee 1</div>
                                </div>
                                <div className="flex w-full py-2">
                                    <div className="flex items-center px-2">
                                        <input type={'checkbox'} />
                                    </div>
                                    <div className="w-16 h-16 flex justify-center px-6 items-center text-2xl font-bold rounded-[50%] bg-plant">E2</div>
                                    <div className="flex items-center px-6">Employee 2</div>
                                </div>
                                <div className="flex w-full py-2">
                                    <div className="flex items-center px-2">
                                        <input type={'checkbox'} />
                                    </div>
                                    <div className="w-16 h-16 flex justify-center px-6 items-center text-2xl font-bold rounded-[50%] bg-plant">E3</div>
                                    <div className="flex items-center px-6">Employee 3</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col px-4 shadow drop-shadow shadow-color5 py-2 mt-6">
                        <span className="absolute w-fit bg-primary text-xl text-color5 px-2 italic -top-4 right-6 font-bold">Price</span>
                        <div className="flex flex-col justify-between py-1 mt-6 bg-transparent relative">
                            <span className="text-plant text-sm absolute left-3 z-10">Retail price</span>
                            <input className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a retail price" />
                        </div>
                        <div className="flex flex-row w-full gap-3">
                            <div className="w-full flex-grow flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                                <span className="text-plant text-sm absolute left-3 z-10">Special price</span>
                                <input className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a special price" />
                            </div>
                            <div className="w-full flex flex-col justify-between py-1 mt-2 bg-transparent relative w-[55%]">
                                {/* <span className="text-plant text-sm absolute left-3 z-10">Percentage</span> */}
                                {/* <span className="bg-red text-sm">{errDisountPer}</span> */}
                                <input type={'number'} className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="" />
                                <span className="absolute right-3 translate-y-1/2 text-xl text-color5">%</span>
                            </div>
                        </div>
                        <div className="flex flex-row w-full gap-3">
                            <div className="w-full flex-grow flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                                <span className="text-plant text-sm absolute left-3 z-10">From</span>
                                <input type={'date'} className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a special price" />
                            </div>
                            <div className="w-full flex-grow flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                                <span className="text-plant text-sm absolute left-3 z-10">To</span>
                                <input type={'date'} className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a special price" />
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between gap-1 mt-4">
                            <Toggle name='Loyality Points' />
                        </div>
                        <div className="flex flex-row items-center justify-between gap-1 mt-4">
                            <Toggle name='Commision' />
                        </div>
                    </div>
                    <div className="flex flex-col px-4 shadow drop-shadow shadow-color5 py-2 mt-6">
                        <span className="absolute w-fit bg-primary text-xl text-color5 px-2 italic -top-4 right-6 font-bold">Duration</span>
                        <div className="w-full flex-grow flex flex-col justify-between py-1 mt-6 bg-transparent relative">
                            <span className="text-plant text-sm absolute left-3 z-10">Duration (min)</span>
                            <input type={'number'} min={1} max={60} className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter duration for service in minutes" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}