import { useState } from "react"
import { useGlobalState } from "../../../Globals/GlobalStates";
import { useNavigate } from "react-router-dom";
import box from '../../../Assets/img/box.png'
import voucher from '../../../Assets/img/discount-voucher.png'
import service from '../../../Assets/img/hair-cutting.png'
import product from '../../../Assets/img/received.png'
import membership from '../../../Assets/img/vip-card.png'

export default function (props) {
    const navigate = useNavigate()
    const [showModal, setShowModal] = useGlobalState('openProductAll');
    const [proType, setProType] = useState('')

    return (
        <div>
            {
                showModal ? (
                    <div className="top-[50px] fixed w-full z-10 ">
                        <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
                            <div className="relative flex-grow w-auto mt-20 mx-auto max-w-2xl rounded shadow drop-shadow-xl z-50 border border-primary">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-primary border border-secondary outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 rounded-t">
                                        <h3 className="p-1 text-xl font-semibold text-color5">
                                            Choose one to continue
                                        </h3>
                                        <button
                                            className="p-1  bg-transparent border-0 text-color5 float-right text-sm leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent hover:opacity-50 text-2xl block outline-none focus:outline-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 bg-red rounded-full text-white">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative flex-auto w-full">
                                        <div className="w-full grid grid-cols-2 gap-4 p-5 justify-center items-center">
                                            <div className={"flex items-center bg-secondary border border-4 border-color4 rounded-md justify-center shadow drop-shadow shadow-color5 relative cursor-pointer hover:bg-color4 hover:text-primary " + (proType === 'Products' ? 'bg-color4 text-primary' : 'text-color4')} onClick={() => { setProType('Products'); navigate('/CompanyProductNew'); setShowModal(false) }}>
                                                <img className="object-scale-down h-44 opacity-20" src={product} alt="" />
                                                <span className="absolute text-xl font-bold">Products</span>
                                            </div>
                                            <div className={"flex items-center bg-secondary border border-4 border-color4 rounded-md justify-center shadow drop-shadow shadow-color5 relative cursor-pointer hover:bg-color4 hover:text-primary " + (proType === 'Service' ? 'bg-color4 text-primary' : 'text-color4')} onClick={() => { setProType('Service'); navigate('/CompanyServiceNew'); setShowModal(false) }}>
                                                <img className="object-scale-down h-44 opacity-20" src={service} alt="" />
                                                <span className="absolute text-xl font-bold">Service</span>
                                            </div>
                                            <div className={"flex items-center bg-secondary border border-4 border-color4 rounded-md justify-center shadow drop-shadow shadow-color5 relative cursor-pointer hover:bg-color4 hover:text-primary " + (proType === 'Package' ? 'bg-color4 text-primary' : 'text-color4')} onClick={() => { setProType('Package'); navigate('/CompanyPackageNew'); setShowModal(false) }}>
                                                <img className="object-scale-down h-44 opacity-20" src={box} alt="" />
                                                <span className="absolute text-xl font-bold">Package</span>
                                            </div>
                                            <div className={"flex items-center bg-secondary border border-4 border-color4 rounded-md justify-center shadow drop-shadow shadow-color5 relative cursor-pointer hover:bg-color4 hover:text-primary " + (proType === 'Membership' ? 'bg-color4 text-primary' : 'text-color4')} onClick={() => { setProType('Membership'); navigate('/CompanyMembershipNew'); setShowModal(false) }}>
                                                <img className="object-scale-down h-44 opacity-20" src={membership} alt="" />
                                                <span className="absolute text-xl font-bold">Membership</span>
                                            </div>
                                            <div className={"flex items-center bg-secondary border border-4 border-color4 rounded-md justify-center shadow drop-shadow shadow-color5 relative cursor-pointer hover:bg-color4 hover:text-primary " + (proType === 'Voucher' ? 'bg-color4 text-primary' : 'text-color4')} onClick={() => setProType('Voucher')}>
                                                <img className="object-scale-down h-44 opacity-20" src={voucher} alt="" />
                                                <span className="absolute text-xl font-bold">Voucher</span>
                                            </div>

                                        </div>
                                    </div >
                                    {/*footer*/}
                                    <div className="flex w-full px-3 py-2">
                                        <div className="flex lg:flex-row flex-col w-full text-center items-center gap-2">
                                            <div className="w-full lg:w-24 bg-secondary lg:order-1 order-2 rounded font-bold uppercase text-sm px-3 py-3 outline-none focus:outline-none mb-1 cursor-pointer"
                                                onClick={() => { setShowModal(false); }}
                                            >
                                                Close
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fixed opacity-60 inset-0 z-40 bg-secondary" onClick={() => setShowModal(false)}></div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}