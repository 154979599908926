import Toggle from '../../utils/Toggle'
import { useEffect, useState } from "react";
import { AiOutlinePlus } from 'react-icons/ai'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from "../../../Globals/GlobalStates"

export default function PlanNew(props) {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate()
    const url = process.env.REACT_APP_BASEURL
    const [refresh, setRefresh] = useGlobalState('refreshPlan')

    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [addPrice, setAddPrice] = useState('')
    const [createdBy, setCreatedBy] = useState('')
    const [createdDate, setCreatedDate] = useState('')

    const [onlineBoking, setOnlineBoking] = useState(false)
    const [appointmentScheduling, setAppointmentScheduling] = useState(false)
    const [socialMediaIntegration, setSocialMediaIntegration] = useState(false)
    const [emailsmsreminder, setemailsmsreminder] = useState(false)
    const [automaticAppointmentConfirmations, setAutomaticAppointmentConfirmations] = useState(false)
    const [resourceManagement, setResourceManagement] = useState(false)
    const [packages, setPackages] = useState(false)
    const [membership, setMemberShip] = useState(false)
    const [voucher, setVoucher] = useState(false)
    const [pos, setPos] = useState(false)
    const [stockAlert, setStockAlert] = useState(false)
    const [invManagement, setInvManagement] = useState(false)
    const [custManagement, setCustManagement] = useState(false)
    const [trackItems, setTrackItems] = useState(false)
    const [loyality, setLoyality] = useState(false)
    const [marketingTools, setMarketingTools] = useState(false)
    const [onlineReview, setOnlineReview] = useState(false)
    const [survey, setSurvey] = useState(false)
    const [staffManagement, setStaffManagement] = useState(false)
    const [commision, setCommision] = useState(false)
    const [staffWorkTime, setStaffWorkTime] = useState(false)
    const [accounting, setAccounting] = useState(false)
    const [analytics, setAnalytics] = useState(false)
    const [phoneChatSupport, setPhoneChatSupport] = useState(false)
    const [training, setTraining] = useState(false)
    const [security, setSecurity] = useState(false)
    const [backups, setBackups] = useState(false)
    const [website, setWebsite] = useState(false)
    const [photo, setPhoto] = useState(false)
    const [storage, setStorage] = useState('')

    const [errName, setErrName] = useState('')
    const [errPrice, setErrPrice] = useState('')

    const addPlan = async () => {
        let valid = true

        if (!name) {
            valid = false;
            setErrName('* Required')
        }
        if (!price) {
            valid = false;
            setErrPrice('* Required')
        }
        if (valid) {
            axios.post(url + 'AddPlan', {
                name,
                price,
                addPrice: addPrice ? addPrice : 0,
                onlineBoking: onlineBoking ? 1 : 0,
                emailsmsreminder: emailsmsreminder ? 1 : 0,
                appointmentScheduling: appointmentScheduling ? 1 : 0,
                socialMediaIntegration: socialMediaIntegration ? 1 : 0,
                automaticAppointmentConfirmations: automaticAppointmentConfirmations ? 1 : 0,
                resourceManagement: resourceManagement ? 1 : 0,
                packages: packages ? 1 : 0,
                membership: membership ? 1 : 0,
                voucher: voucher ? 1 : 0,
                pos: pos ? 1 : 0,
                stockAlert: stockAlert ? 1 : 0,
                invManagement: invManagement ? 1 : 0,
                custManagement: custManagement ? 1 : 0,
                trackItems: trackItems ? 1 : 0,
                loyality: loyality ? 1 : 0,
                marketingTools: marketingTools ? 1 : 0,
                onlineReview: onlineReview ? 1 : 0,
                survey: survey ? 1 : 0,
                staffManagement: staffManagement ? 1 : 0,
                commision: commision ? 1 : 0,
                staffWorkTime: staffWorkTime ? 1 : 0,
                accounting: accounting ? 1 : 0,
                analytics: analytics ? 1 : 0,
                phoneChatSupport: phoneChatSupport ? 1 : 0,
                training: training ? 1 : 0,
                security: security ? 1 : 0,
                backups: backups ? 1 : 0,
                website: website ? 1 : 0,
                photo: photo ? 1 : 0,
                storage: storage ? 1 : 0,
                active: 1,
                createdBy,
                createdDate
            }).then((response) => {
                response = response.data
                if (response.result) {
                    setRefresh(true)
                    setShowModal(false)
                }
                else {

                }
            })
        }
        else {

        }
    }

    useEffect(() => {
        if (sessionStorage.getItem('sgid')) {
            let date = new Date()
            setCreatedBy(sessionStorage.getItem('sgid'))
            setCreatedDate(date)
        }
        else
            navigate('/Login')
    }, [])

    return (
        <>
            <button
                className='flex rounded float-right p-2 bg-color4 mb-4 justify-center items-center px-3text-[#ffff] hover:opacity-60 focus:border-blue ease-linear transition-all duration-150'
                type="button"
                onClick={() => setShowModal(true)}
            >
                <AiOutlinePlus size={20} />
            </button>
            {
                showModal ? (
                    <div className="top-[50px] fixed w-full z-10 ">
                        <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
                            <div className="relative flex-grow w-auto mt-20 mx-auto max-w-2xl rounded shadow drop-shadow-xl z-50 border border-primary">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-color3 border border-secondary outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 rounded-t">
                                        <h3 className="text-xl font-semibold text-color5">
                                            New Plan
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-color5 float-right text-sm leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent hover:opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative flex-auto">
                                        <div className="flex-grow px-5 bg-[#fff]] m-0 mt-4 mb-4 ml-2">
                                            <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 justify-between">
                                                <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                                                    <span className="text-plant text-sm absolute left-4 z-10">Plan Name <span className='text-sm text-red'>{errName}</span></span>
                                                    <input className="w-full h-[58px] bg-secondary focus:scale-105 outline-none pt-1 px-5 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a plan name" value={name} onChange={(e) => { setErrName(''); setName(e.target.value) }} />
                                                </div>
                                                <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                                                    <span className="text-plant text-sm absolute left-4 z-10">Price <span className='text-sm text-red'>{errPrice}</span></span>
                                                    <input type={'number'} className="w-full h-[58px] bg-secondary focus:scale-105 outline-none pt-1 px-5 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter price of plan" value={price} onChange={(e) => { setErrPrice(''); setPrice(e.target.value) }} />
                                                </div>
                                                <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                                                    <span className="text-plant text-sm absolute left-4 z-10">Additional User Price</span>
                                                    <input type={'number'} className="w-full h-[58px] bg-secondary focus:scale-105 outline-none pt-1 px-5 placeholder:italic placeholder:text-sm rounded-xl" placeholder="price of addtional user" value={addPrice} onChange={(e) => setAddPrice(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='grid lg:grid-cols-2 gap-4 justify-between mt-1'>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Online Booking' enabled={onlineBoking} onClickValue={() => setOnlineBoking(!onlineBoking)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Appointment Scheduling' enabled={appointmentScheduling} onClickValue={() => setAppointmentScheduling(!appointmentScheduling)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Email/SMS Reminder' enabled={emailsmsreminder} onClickValue={() => setemailsmsreminder(!emailsmsreminder)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Social media integration' enabled={socialMediaIntegration} onClickValue={() => setSocialMediaIntegration(!socialMediaIntegration)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Automatic appointment confirmations' enabled={automaticAppointmentConfirmations} onClickValue={() => setAutomaticAppointmentConfirmations(!automaticAppointmentConfirmations)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Resource Management' enabled={resourceManagement} onClickValue={() => setResourceManagement(!resourceManagement)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Package' enabled={packages} onClickValue={() => setPackages(!packages)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Membership' enabled={membership} onClickValue={() => setMemberShip(!membership)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Voucher' enabled={voucher} onClickValue={() => setVoucher(!voucher)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='POS (point of sale)' enabled={pos} onClickValue={() => setPos(!pos)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Stock alerts and reorder level' enabled={stockAlert} onClickValue={() => setStockAlert(!stockAlert)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Inventory management' enabled={invManagement} onClickValue={() => setInvManagement(!invManagement)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Customer management' enabled={custManagement} onClickValue={() => setCustManagement(!custManagement)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Track items in use' enabled={trackItems} onClickValue={() => setTrackItems(!trackItems)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Loyalty program' enabled={loyality} onClickValue={() => setLoyality(!loyality)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Marketing tools' enabled={marketingTools} onClickValue={() => setMarketingTools(!marketingTools)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Online review tool' enabled={onlineReview} onClickValue={() => setOnlineReview(!onlineReview)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Survey' enabled={survey} onClickValue={() => setSurvey(!survey)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Staff management' enabled={staffManagement} onClickValue={() => setStaffManagement(!staffManagement)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Commission Exceptions' enabled={commision} onClickValue={() => setCommision(!commision)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Staff workschedule' enabled={staffWorkTime} onClickValue={() => setStaffWorkTime(!staffWorkTime)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Accounting' enabled={accounting} onClickValue={() => setAccounting(!accounting)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Analytics and reporting' enabled={analytics} onClickValue={() => setAnalytics(!analytics)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Phone and Chat Support' enabled={phoneChatSupport} onClickValue={() => setPhoneChatSupport(!phoneChatSupport)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Free Training' enabled={training} onClickValue={() => setTraining(!training)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Advanced security' enabled={security} onClickValue={() => setSecurity(!security)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Daily backups' enabled={backups} onClickValue={() => setBackups(!backups)} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Website building' enabled={website} onClickValue={() => setWebsite(!website)} />
                                                    </div>
                                                </div>
                                                <div className='flex flex-row items-center'>
                                                    <div className="form-check form-switch">
                                                        <Toggle name='Photos' enabled={photo} onClickValue={() => setPhoto(!photo)} />
                                                    </div>
                                                    {
                                                        photo
                                                            ? <div className='relative'>
                                                                <span className='absolute right-2 top-1 text-color5 font-bold italic'>GB</span>
                                                                <input type={'number'} value={storage} onChange={(e) => setStorage(e.target.value)} className='bg-secondary p-1 px-2 rounded placeholder:text-sm placeholder:italic outline-none scale-101' placeholder='Enter a storage limit' />
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                    {/*footer*/}
                                    <div className="flex w-full px-3 py-2">
                                        <div className="flex lg:flex-row flex-col w-full text-center items-center gap-2">
                                            <div className="w-full lg:w-24 bg-secondary lg:order-1 order-2 rounded font-bold uppercase text-sm px-3 py-3 outline-none focus:outline-none mb-1 cursor-pointer"
                                                onClick={() => { setShowModal(false); }}
                                            >
                                                Close
                                            </div>
                                            <div className="w-full flex-grow bg-color4 lg:order-2 order-1 rounded font-bold uppercase text-xs lg:text-sm px-3 py-3 outline-none focus:outline-none mb-1 cursor-pointer"
                                                onClick={() => addPlan()}
                                            >
                                                Save
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fixed opacity-60 inset-0 z-40 bg-secondary" onClick={() => setShowModal(false)}></div>
                        </div>
                    </div>
                ) : null
            }
        </>
    );
}