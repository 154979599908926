import ContactAdd from "./ContactAdd"
import ContactCard from "./ContactCard"
import { useNavigate } from 'react-router-dom'
import { AiOutlinePlus } from 'react-icons/ai'
import { useEffect, useMemo, useState } from "react"
import { getcontacts } from "../../../Model/ContactModel"
import { CiLineHeight } from "react-icons/ci"
import BackButton from "../../Admin/Controls/BackButton"

export default function CustomerView(props) {
    const navigate = useNavigate()
    const [customerlist, setcustomerlist] = useState([])
    const [showprogress, setshowprogress] = useState(false)
    const [searchkey, setsearchkey] = useState('')
    const [sortlist, setsortlit] = useState({
        byname: 0,
        byphone: 0,
        byemail: 0,
        bylastvisit: 0,
        bytotalpurchase: 0
    })

    const getcustomer = () => {
        setshowprogress(true)
        getcontacts(async (response) => {
            setcustomerlist(response.data.data)
            setshowprogress(false)
        })
    }

    const getcolor = (v) => {
        if (v % 5 == 0)
            return 'border-color5'
        else if (v % 4 == 0)
            return 'border-color7'
        else if (v % 3 == 0)
            return 'border-color4'
        else if (v % 2 == 0)
            return 'border-color8'
        else if (v % 1 == 0)
            return 'border-color1'
    }

    let filtercustomer = useMemo(() => {
        return customerlist.filter(i => {
            return i.name.toLowerCase().includes(searchkey.toLowerCase()) || i.phone.toLowerCase().includes(searchkey.toLowerCase()) || i.email.toLowerCase().includes(searchkey.toLowerCase())
        })
    }, [customerlist, searchkey])

    const sortbyname = () => {
        if (sortlist.byname === 1) {
            filtercustomer.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
        } else if (sortlist.byname === -1) {
            filtercustomer.sort((a, b) => {
                if (a.name < b.name) {
                    return 1;
                }
                if (a.name > b.name) {
                    return -1;
                }
                return 0;
            });
        } else {
            filtercustomer = customerlist
        }
    }

    const sortbyphone = () => {
        if (sortlist.byphone === 1) {
            filtercustomer.sort((a, b) => {
                if (a.phone < b.phone) {
                    return -1;
                }
                if (a.phone > b.phone) {
                    return 1;
                }
                return 0;
            });
        } else if (sortlist.byphone === -1) {
            filtercustomer.sort((a, b) => {
                if (a.phone < b.phone) {
                    return 1;
                }
                if (a.phone > b.phone) {
                    return -1;
                }
                return 0;
            });
        } else {
            filtercustomer = customerlist
        }
    }

    const sortbyemail = () => {
        if (sortlist.byemail === 1) {
            filtercustomer.sort((a, b) => {
                if (a.email < b.email) {
                    return -1;
                }
                if (a.email > b.email) {
                    return 1;
                }
                return 0;
            });
        } else if (sortlist.byphone === -1) {
            filtercustomer.sort((a, b) => {
                if (a.email < b.email) {
                    return 1;
                }
                if (a.email > b.email) {
                    return -1;
                }
                return 0;
            });
        } else {
            filtercustomer = customerlist
        }
    }

    const sortbylastvisit = () => {
        if (sortlist.bylastvisit === 1) {
            filtercustomer.sort((a, b) => {
                if (a.lastvisit < b.lastvisit) {
                    return -1;
                }
                if (a.lastvisit > b.lastvisit) {
                    return 1;
                }
                return 0;
            });
        } else if (sortlist.byphone === -1) {
            filtercustomer.sort((a, b) => {
                if (a.lastvisit < b.lastvisit) {
                    return 1;
                }
                if (a.lastvisit > b.lastvisit) {
                    return -1;
                }
                return 0;
            });
        } else {
            filtercustomer = customerlist
        }
    }

    const sortbytotalpurchase = () => {
        if (sortlist.bytotalpurchase === 1) {
            filtercustomer.sort((a, b) => {
                if (a.totalpurchase < b.totalpurchase) {
                    return -1;
                }
                if (a.totalpurchase > b.totalpurchase) {
                    return 1;
                }
                return 0;
            });
        } else if (sortlist.byphone === -1) {
            filtercustomer.sort((a, b) => {
                if (a.totalpurchase < b.totalpurchase) {
                    return 1;
                }
                if (a.totalpurchase > b.totalpurchase) {
                    return -1;
                }
                return 0;
            });
        } else {
            filtercustomer = customerlist
        }
    }

    useMemo(() => {
        sortbyphone()
    }, [sortlist.byphone])

    useMemo(() => {
        sortbyname()
    }, [sortlist.byname])

    useMemo(() => {
        sortbyemail()
    }, [sortlist.byemail])

    useMemo(() => {
        sortbylastvisit()
    }, [sortlist.bylastvisit])

    useMemo(() => {
        sortbytotalpurchase()
    }, [sortlist.bytotalpurchase])

    const handlesortlist = (i) => {
        if (i === 'name') {
            if (sortlist.byname === 0)
                setsortlit({ ...sortlist, byname: 1 })
            else if (sortlist.byname === 1)
                setsortlit({ ...sortlist, byname: -1 })
            else
                setsortlit({ ...sortlist, byname: 0 })
        } else if (i === 'phone') {
            if (sortlist.byphone === 0)
                setsortlit({ ...sortlist, byphone: 1 })
            else if (sortlist.byphone === 1)
                setsortlit({ ...sortlist, byphone: -1 })
            else
                setsortlit({ ...sortlist, byphone: 0 })
        } else if (i === 'email') {
            if (sortlist.byemail === 0)
                setsortlit({ ...sortlist, byemail: 1 })
            else if (sortlist.byemail === 1)
                setsortlit({ ...sortlist, byemail: -1 })
            else
                setsortlit({ ...sortlist, byemail: 0 })
        } else if (i === 'lastvisit') {
            if (sortlist.bylastvisit === 0)
                setsortlit({ ...sortlist, bylastvisit: 1 })
            else if (sortlist.bylastvisit === 1)
                setsortlit({ ...sortlist, bylastvisit: -1 })
            else
                setsortlit({ ...sortlist, bylastvisit: 0 })
        } else if (i === 'totalpurchase') {
            if (sortlist.bytotalpurchase === 0)
                setsortlit({ ...sortlist, bytotalpurchase: 1 })
            else if (sortlist.bytotalpurchase === 1)
                setsortlit({ ...sortlist, bytotalpurchase: -1 })
            else
                setsortlit({ ...sortlist, bytotalpurchase: 0 })
        }
    }

    useEffect(() => {
        getcustomer()
    }, [])

    return (
        <div className="w-full bg-secondary px-2 z-1 relative prevent-select">
            <div className="flex w-full p-3 justify-end items-center z-1">
                <div className="pr-8">
                    <BackButton />
                </div>
                <div className="flex-grow">
                    <div className="relative md:w-[50%] w-full">
                        <input className="p-2 px-8 w-full bg-primary placeholder:italic rounded-md outline-none focus:border focus:border-color5 " placeholder="Search customer" value={searchkey} onChange={(e) => setsearchkey(e.target.value)} />
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute left-1 top-1/2 -translate-y-1/2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                        {
                            searchkey
                                ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 absolute right-1 top-1/2 -translate-y-1/2 cursor-pointer" onClick={() => setsearchkey('')}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                : null
                        }
                    </div>
                </div>
                <div className="z-20">
                    <button className='text-secondary p-2 bg-color4 md:rounded md:static fixed bottom-3 right-3 rounded-full' onClick={() => navigate('/CompanyCustomerAdd')}><AiOutlinePlus className='lg:w-5 w-8 lg:h-5 h-8' /></button>
                </div>
            </div>
            <div className="w-full overflow-auto">
                <table className="w-full table-auto border-separate border-spacing-y-2 z-1 scroll-auto">
                    <thead>
                        <tr className="text-left">
                            <th scope="col" className="px-4 sticky left-0 h-fit bg-secondary"></th>
                            <th scope="col" className="px-4 sticky left-0 h-fit bg-secondary font-normal text-sm" onClick={() => handlesortlist('name')}>
                                <div className="inline-flex items-center">
                                    <span className="pr-1">Name</span>
                                    {
                                        sortlist.byname === -1
                                            ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                <path fillRule="evenodd" d="M10 15a.75.75 0 01-.75-.75V7.612L7.29 9.77a.75.75 0 01-1.08-1.04l3.25-3.5a.75.75 0 011.08 0l3.25 3.5a.75.75 0 11-1.08 1.04l-1.96-2.158v6.638A.75.75 0 0110 15z" clipRule="evenodd" />
                                            </svg>
                                            : sortlist.byname === 1
                                                ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                    <path fillRule="evenodd" d="M10 5a.75.75 0 01.75.75v6.638l1.96-2.158a.75.75 0 111.08 1.04l-3.25 3.5a.75.75 0 01-1.08 0l-3.25-3.5a.75.75 0 111.08-1.04l1.96 2.158V5.75A.75.75 0 0110 5z" clipRule="evenodd" />
                                                </svg>
                                                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3 h-3">
                                                    <path fillRule="evenodd" d="M2.24 6.8a.75.75 0 001.06-.04l1.95-2.1v8.59a.75.75 0 001.5 0V4.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0L2.2 5.74a.75.75 0 00.04 1.06zm8 6.4a.75.75 0 00-.04 1.06l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75a.75.75 0 00-1.5 0v8.59l-1.95-2.1a.75.75 0 00-1.06-.04z" clipRule="evenodd" />
                                                </svg>
                                    }
                                </div>
                            </th>
                            <th scope="col" className="font-normal text-sm px-4" onClick={() => handlesortlist('phone')}>
                                <div className="inline-flex items-center">
                                    <span className="pr-1">Phone</span>
                                    {
                                        sortlist.byphone === -1
                                            ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                <path fillRule="evenodd" d="M10 15a.75.75 0 01-.75-.75V7.612L7.29 9.77a.75.75 0 01-1.08-1.04l3.25-3.5a.75.75 0 011.08 0l3.25 3.5a.75.75 0 11-1.08 1.04l-1.96-2.158v6.638A.75.75 0 0110 15z" clipRule="evenodd" />
                                            </svg>
                                            : sortlist.byphone === 1
                                                ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                    <path fillRule="evenodd" d="M10 5a.75.75 0 01.75.75v6.638l1.96-2.158a.75.75 0 111.08 1.04l-3.25 3.5a.75.75 0 01-1.08 0l-3.25-3.5a.75.75 0 111.08-1.04l1.96 2.158V5.75A.75.75 0 0110 5z" clipRule="evenodd" />
                                                </svg>
                                                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3 h-3">
                                                    <path fillRule="evenodd" d="M2.24 6.8a.75.75 0 001.06-.04l1.95-2.1v8.59a.75.75 0 001.5 0V4.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0L2.2 5.74a.75.75 0 00.04 1.06zm8 6.4a.75.75 0 00-.04 1.06l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75a.75.75 0 00-1.5 0v8.59l-1.95-2.1a.75.75 0 00-1.06-.04z" clipRule="evenodd" />
                                                </svg>
                                    }
                                </div>
                            </th>
                            <th scope="col" className="font-normal text-sm px-4" onClick={() => handlesortlist('email')}>
                                <div className="inline-flex items-center">
                                    <span className="pr-1">Email</span>
                                    {
                                        sortlist.byemail === -1
                                            ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                <path fillRule="evenodd" d="M10 15a.75.75 0 01-.75-.75V7.612L7.29 9.77a.75.75 0 01-1.08-1.04l3.25-3.5a.75.75 0 011.08 0l3.25 3.5a.75.75 0 11-1.08 1.04l-1.96-2.158v6.638A.75.75 0 0110 15z" clipRule="evenodd" />
                                            </svg>
                                            : sortlist.byemail === 1
                                                ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                    <path fillRule="evenodd" d="M10 5a.75.75 0 01.75.75v6.638l1.96-2.158a.75.75 0 111.08 1.04l-3.25 3.5a.75.75 0 01-1.08 0l-3.25-3.5a.75.75 0 111.08-1.04l1.96 2.158V5.75A.75.75 0 0110 5z" clipRule="evenodd" />
                                                </svg>
                                                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3 h-3">
                                                    <path fillRule="evenodd" d="M2.24 6.8a.75.75 0 001.06-.04l1.95-2.1v8.59a.75.75 0 001.5 0V4.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0L2.2 5.74a.75.75 0 00.04 1.06zm8 6.4a.75.75 0 00-.04 1.06l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75a.75.75 0 00-1.5 0v8.59l-1.95-2.1a.75.75 0 00-1.06-.04z" clipRule="evenodd" />
                                                </svg>
                                    }
                                </div>
                            </th>
                            <th scope="col" className="font-normal text-sm px-4" onClick={() => handlesortlist('lastvisit')}>
                                <div className="inline-flex items-center">
                                    <span className="pr-1">Last visit</span>
                                    {
                                        sortlist.bylastvisit === -1
                                            ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                <path fillRule="evenodd" d="M10 15a.75.75 0 01-.75-.75V7.612L7.29 9.77a.75.75 0 01-1.08-1.04l3.25-3.5a.75.75 0 011.08 0l3.25 3.5a.75.75 0 11-1.08 1.04l-1.96-2.158v6.638A.75.75 0 0110 15z" clipRule="evenodd" />
                                            </svg>
                                            : sortlist.bylastvisit === 1
                                                ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                    <path fillRule="evenodd" d="M10 5a.75.75 0 01.75.75v6.638l1.96-2.158a.75.75 0 111.08 1.04l-3.25 3.5a.75.75 0 01-1.08 0l-3.25-3.5a.75.75 0 111.08-1.04l1.96 2.158V5.75A.75.75 0 0110 5z" clipRule="evenodd" />
                                                </svg>
                                                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3 h-3">
                                                    <path fillRule="evenodd" d="M2.24 6.8a.75.75 0 001.06-.04l1.95-2.1v8.59a.75.75 0 001.5 0V4.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0L2.2 5.74a.75.75 0 00.04 1.06zm8 6.4a.75.75 0 00-.04 1.06l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75a.75.75 0 00-1.5 0v8.59l-1.95-2.1a.75.75 0 00-1.06-.04z" clipRule="evenodd" />
                                                </svg>
                                    }
                                </div>
                            </th>
                            <th scope="col" className="font-normal text-sm px-4" onClick={() => handlesortlist('totalpurchase')}>
                                <div className="inline-flex items-center">
                                    <span className="pr-1">Total purchase</span>
                                    {
                                        sortlist.bytotalpurchase === -1
                                            ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                <path fillRule="evenodd" d="M10 15a.75.75 0 01-.75-.75V7.612L7.29 9.77a.75.75 0 01-1.08-1.04l3.25-3.5a.75.75 0 011.08 0l3.25 3.5a.75.75 0 11-1.08 1.04l-1.96-2.158v6.638A.75.75 0 0110 15z" clipRule="evenodd" />
                                            </svg>
                                            : sortlist.bytotalpurchase === 1
                                                ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                    <path fillRule="evenodd" d="M10 5a.75.75 0 01.75.75v6.638l1.96-2.158a.75.75 0 111.08 1.04l-3.25 3.5a.75.75 0 01-1.08 0l-3.25-3.5a.75.75 0 111.08-1.04l1.96 2.158V5.75A.75.75 0 0110 5z" clipRule="evenodd" />
                                                </svg>
                                                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3 h-3">
                                                    <path fillRule="evenodd" d="M2.24 6.8a.75.75 0 001.06-.04l1.95-2.1v8.59a.75.75 0 001.5 0V4.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0L2.2 5.74a.75.75 0 00.04 1.06zm8 6.4a.75.75 0 00-.04 1.06l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75a.75.75 0 00-1.5 0v8.59l-1.95-2.1a.75.75 0 00-1.06-.04z" clipRule="evenodd" />
                                                </svg>
                                    }
                                </div>
                            </th>
                            <th scope="col" className="font-normal text-sm px-4">Status</th>
                            <th scope="col" className="font-normal text-sm px-4"></th>
                        </tr>
                    </thead>
                    <tbody className=" ">
                        {
                            props.contactType === 'customer'
                                ? (filtercustomer
                                    ? filtercustomer.map((x, index) => {
                                        return <ContactCard key={index} data={x} contactType={props.contactType} borderColor={getcolor(index + 1)} />
                                    })
                                    : null)
                                : null
                        }
                    </tbody>
                </table>
                {
                    filtercustomer.length === 0
                        ? <div className="flex w-full justify-center text-color5 italic text-sm">No data to show</div>
                        : null
                }
            </div>
            <div className="flex w-full p-2 justify-end z-1">
                {
                    showprogress
                        ? <span className="m-auto p-auto items-center loader z-10"></span>
                        : null
                }
            </div>
        </div >
    )
}