import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import Toggle from '../../utils/Toggle'
import { MdOutlineExpandLess, MdOutlineExpandMore, MdAdd } from 'react-icons/md'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import BackButton from "../../Admin/Controls/BackButton"
import ContactStaffModel from "./ContactStaffModel"
import { cnt, addcontact } from "../../../Model/ContactModel"
import { format } from 'date-fns'
import Required from "../../utils/Required";

export default function ContactAdd(props) {
    const navigate = useNavigate()
    const [addressCollapse, setAddressCollapse] = useState(false)
    const [additionalCollapse, setAdditionalCollapse] = useState(false)
    const [showCollapseIcon, setShowCollapseIcon] = useState(true)
    const [saving, setsaving] = useState(false)
    let index = 0

    const [contact, setcontact] = useState(cnt)
    const [errname, seterrname] = useState(false)
    const [errphone, seterrphone] = useState(false)

    const [areacodelist, setareacodelist] = useState([
        { code: '+961', country: 'Lebanon' },
        { code: '+243', country: 'Congo DRC' },
        { code: '+1', country: 'USA' }
    ])

    const [areacodeselected, setareacodeselected] = useState(areacodelist[0].code)
    const [showareacode, setshowareacode] = useState(false)

    const expandedAddress = () => {
        if (isMobile) {
            if (addressCollapse)
                setAddressCollapse(false)
            else
                setAddressCollapse(true)
        }
    }

    const expandedAdditional = () => {
        if (isMobile) {
            if (additionalCollapse)
                setAdditionalCollapse(false)
            else
                setAdditionalCollapse(true)
        }
    }

    const handleChangeStaff = (e) => {
        setcontact({ ...contact, staff: e })
    }

    const addInput = async () => {
        let arr = contact.note
        arr.push({ index: index, val: '', createdby: sessionStorage.getItem('sgid'), createddate: new Date() })
        await setcontact({ ...contact, note: arr })
        await index++
    }

    const changeNote = async (e, index) => {
        const newArray = await contact.note.map((item, i) => {
            if (index === i) {
                return { ...item, index: i, val: e.target.value };
            } else {
                return item;
            }
        });
        await setcontact({ ...contact, note: newArray });
    }

    const handleAddContact = async () => {
        if (contact.name && contact.phone) {
            await setsaving(() => {
                return true
            })
            await setcontact(c => ({ ...contact, type: 1 }))
            await addcontact(contact, (response) => {
                response = response.data
                if (response.status) {
                    setcontact(cnt)
                    setsaving(() => {
                        return false
                    })
                } else {
                    setsaving(() => {
                        return false
                    })
                }
            })
        } else {
            if (!contact.name)
                seterrname(() => { return true })
            if (!contact.phone)
                seterrphone(() => { return true })
            // console.log('Some required fields not filled')
        }
    }

    useEffect(() => {
        if (!isMobile) {
            setAddressCollapse(true)
            setAdditionalCollapse(true)
            setShowCollapseIcon(false)
        }
    }, [])

    return (
        <div className="prevent-select lg:mx-auto border-none rounded-lg shadow-lg shadow-primary relative grid grid-rows bg-secondary border border-primary outline-none focus:outline-none cursor-default">
            {/*header*/}
            <div className="flex flex-row border-b border-color5 relative justify-start items-center p-5 rounded-t w-full">
                <div className="flex justify-center items-center">
                    <BackButton />
                </div>
                <div className="flex justify-center items-center">
                    <h3 className="text-xl font-semibold px-5">
                        Add Customer
                    </h3>
                </div>
                {
                    saving
                        ? <div className="absolute inset-0 opacity-80 bg-primary"></div>
                        : null
                }
            </div>
            {/*body*/}
            <div className="grid lg:grid-cols-2 grid-rows lg:px-5 px-2 mb-7 w-full relative">
                <div className="flex-grow px-5 bg-[#fff]]">
                    <div className='grid w-full'>
                        <div className="flex whitespace-nowrap items-center py-4 pr-4">
                            <span className="text-color5 px-2 text-md font-bold uppercase">Personal Informartion</span>
                            <hr className="w-full h-[1px]  bg-color5 border-0" />
                        </div>
                        <div className='grid lg:grid-cols-1 grid-rows gap-1 pb-8 lg:border-none border-b border-plant'>
                            <div className='flex flex-col justify-between col-span-2 py-1 mt-2 bg-transparent relative'>
                                <span className={"text-sm absolute top-2 left-3 " + ('text-plant')}>Name</span>
                                <input className={"w-full h-[58px] bg-primary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl "} placeholder="Enter a name" value={contact.name} onChange={(e) => { seterrname(false); setcontact({ ...contact, name: e.target.value }) }} onBlur={() => !contact.name ? seterrname(true) : seterrname(false)} />
                                {
                                    errname
                                        ? <Required />
                                        : null
                                }
                            </div>
                            <div className='flex flex-col justify-between py-1 md:col-span-1 col-span-2 mt-2 bg-transparent relative'>
                                <span className={"text-sm absolute top-2 left-3 " + 'text-plant'}>Date of Birthday</span>
                                <input type="date" className={"w-full h-[58px] bg-primary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl "} placeholder="Enter a date of birth" value={contact.dob} onChange={(e) => setcontact({ ...contact, dob: e.target.value })} />
                            </div>
                            <div className='flex flex-col justify-between py-1 mt-2 md:col-span-1 col-span-2 bg-transparent relative'>
                                <span className={"text-sm absolute top-2 left-3 " + ('text-plant')}>Phone</span>
                                <span className={"absolute top-1 translate-y-[18px] left-3 cursor-pointer"} onClick={() => setshowareacode(!showareacode)}>{areacodeselected}</span>
                                {
                                    showareacode
                                        ? <div className="absolute flex flex-col gap-1 z-20 bg-primary -top-5 translate-y-1/2 border border-color5 rounded-md p-1">
                                            <input className="w-full outline-none bg-secondary placeholder:italic p-1 rounded-md my-0.5" placeholder="Search" />
                                            {
                                                areacodelist
                                                    ? areacodelist.map(x => {
                                                        return <span key={x.code} className="text-sm italic hover:bg-plant cursor-pointer p-1 px-3" onClick={() => { setareacodeselected(x.code); setshowareacode(false) }}>{`${x.country} - (${x.code})`}</span>

                                                    })
                                                    : null
                                            }
                                        </div>
                                        : null
                                }
                                <input type="text" className={"w-full h-[58px] bg-primary focus:bg-black outline-none px-4 pb-0 pl-14 placeholder:italic placeholder:text-sm rounded-xl "} placeholder="Enter phone number" value={contact.phone} onChange={(e) => { seterrphone(false); setcontact({ ...contact, phone: e.target.value }) }} onBlur={() => !contact.phone ? seterrphone(true) : seterrname(false)} />
                                {
                                    errphone
                                        ? <Required />
                                        : null
                                }
                            </div>
                            <div className='flex flex-col justify-between py-1 col-span-2 mt-2 bg-transparent relative'>
                                <span className={"text-sm absolute top-2 left-3 " + 'text-plant'}>Email</span>
                                <input className={"w-full h-[58px] bg-primary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl "} placeholder="Enter email" value={contact.email} onChange={(e) => setcontact({ ...contact, email: e.target.value })} />
                            </div>
                            <div className='grid grid-rows col-span-2 p-1 w-full'>
                                <label className='m-0 text-plant text-sm p-2'>Gender</label>
                                <div className='grid grid-cols-3 gap-3 border border-color5  rounded p-1 bg-primary text-sm'>
                                    <button className={'flex w-full justify-center items-center rounded text-color5 p-1 ' + (contact.gender === 'Unspecific' ? 'bg-secondary text-white border-color5 shadow' : '')} onClick={() => setcontact({ ...contact, gender: 'Unspecific' })}>
                                        Unspecific
                                    </button>
                                    <button className={'flex w-full justify-center items-center rounded text-color5 p-1 ' + (contact.gender === 'Male' ? 'bg-secondary text-white border-color5 shadow' : '')} onClick={() => setcontact({ ...contact, gender: 'Male' })}>
                                        Male
                                    </button>
                                    <button className={'flex justify-center items-center rounded text-color5 p-1 ' + (contact.gender === 'Female' ? 'bg-secondary text-white border-color5 shadow' : '')} onClick={() => setcontact({ ...contact, gender: 'Female' })}>
                                        Female
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className=" lg:border-none border-b border-plant lg:pb-0 pb-2">
                            <div className="flex items-center py-4 pr-4" onClick={expandedAddress}>
                                <span className="text-color5 px-2 text-md font-bold uppercase">Address</span>
                                <hr className="w-full h-[1px] bg-color5 border-0" />
                                {/* {
                                    showCollapseIcon
                                        ? addressCollapse
                                            ? <MdOutlineExpandLess className="text-plant" size={30} />
                                            : <MdOutlineExpandMore className="text-plant" size={30} />
                                        : null
                                } */}
                                {/* <div class="flex-grow h-px bg-color5 "></div> */}
                            </div>
                            {
                                addressCollapse
                                    ? <div className='grid lg:grid-cols-2 grid-cols-1 gap-1 grid-rows justify-between'>
                                        <div className='flex flex-col justify-between py-1 mt-2 bg-transparent relative'>
                                            <span className={"text-sm absolute top-2 left-3 " + 'text-plant'}>City</span>
                                            <input className={"w-full h-[58px] bg-primary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl "} placeholder="Enter a city" value={contact.city} onChange={(e) => setcontact({ ...contact, city: e.target.value })} />
                                        </div>
                                        <div className='flex flex-col justify-between py-1 mt-2 bg-transparent relative'>
                                            <span className={"text-sm absolute top-2 left-3 " + 'text-plant'}>Street</span>
                                            <input className={"w-full h-[58px] bg-primary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl "} placeholder="Enter a street" value={contact.street} onChange={(e) => setcontact({ ...contact, street: e.target.value })} />
                                        </div>
                                        <div className='flex flex-col justify-between py-1 lg:col-span-2 mt-2 bg-transparent relative'>
                                            <span className={"text-sm absolute top-2 left-3 " + 'text-plant'}>Address</span>
                                            <input className={"w-full h-[58px] bg-primary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl "} placeholder="Enter a address details" value={contact.address} onChange={(e) => setcontact({ ...contact, address: e.target.value })} />
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className="flex flex-col flex-grow p-5 bg-[#fff]]">
                    <div className='lg:order-1 order-2 flex flex-col w-full border border-color5 rounded pb-5'>
                        <div className="flex items-center py-4 pr-4">
                            <span className="flex-shrink text-color5 px-2 text-md font-bold uppercase">Notification</span>
                            {/* <hr className="w-full h-[1px]  bg-color5 border-0" /> */}
                        </div>
                        <div className="flex flex-col px-5 py-2">
                            <Toggle name='Email notification' enabled={contact.emailnot} onClickValue={() => setcontact({ ...contact, emailnotification: !contact.emailnot })} />
                        </div>
                        <div className="flex flex-col px-5 py-2">
                            <Toggle name='Text marketing' enabled={contact.textmarknot} onClickValue={() => setcontact({ ...contact, textmarketingnotification: !contact.textmarknot })} />
                        </div>
                        <div className="flex flex-col px-5 py-2">
                            <Toggle name='Email marketing' enabled={contact.emailmarknot} onClickValue={() => setcontact({ ...contact, emailmarketingnotification: !contact.emailmarknot })} />
                        </div>
                    </div>
                    <div className="lg:order-2 order-1  lg:border-none border-b border-plant lg:mb-0 mb-2 items-center py-4 pr-4">
                        <div className="flex flex-row items-center flex-grow whitespace-nowrap" onClick={expandedAdditional}>
                            <span className="flex-grow text-color5 px-2 text-md text-left font-bold uppercase">Additional Info</span>
                            <hr className="w-full h-[1px]  bg-color5 border-0" />
                            {/* {
                                showCollapseIcon
                                    ? additionalCollapse
                                        ? <MdOutlineExpandLess className="text-plant" size={30} />
                                        : <MdOutlineExpandMore className="text-plant" size={30} />
                                    : null
                            } */}
                        </div>

                        {
                            additionalCollapse
                                ? <div className='flex flex-col'>
                                    <div className='grid px-1'>
                                        <label className='m-0 text-color5 p-2'>Client Source</label>
                                        <div className='grid grid-cols-2 gap-3 border border-color5 rounded p-1 bg-primary text-sm'>
                                            <button className={'flex w-full justify-center items-center rounded text-color5 p-1 ' + (contact.clientSource === 'Walkin' ? 'bg-secondary text-white border-color5 shadow' : '')} onClick={() => setcontact({ ...contact, source: 'Walkin' })}>
                                                Walk-in
                                            </button>
                                            <button className={'flex w-full justify-center items-center rounded text-color5 p-1 ' + (contact.clientSource === 'Reservation' ? 'bg-secondary text-white border-color5 shadow' : '')} onClick={() => setcontact({ ...contact, clientSource: 'Reservation' })}>
                                                Reservation
                                            </button>
                                        </div>
                                    </div>
                                    <div className='grid px-1'>
                                        <ContactStaffModel value={contact.staff.name} onChange={(e) => handleChangeStaff(e)} />
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    <div className="lg:order-2 order-1  lg:border-none border-b border-plant lg:mb-0 mb-2 items-center py-4 pr-4">
                        <div className="flex flex-row flex-grow">
                            <span className="flex items-center flex-grow text-color5 px-2 text-md text-left font-bold uppercase">Note</span>
                            <MdAdd className="text-plant p-1" size={30} onClick={addInput} />
                        </div>
                        <div className='flex flex-col'>

                            <div className='grid px-1'>
                                {
                                    contact.note
                                        ? contact.note.map((d, index) => {
                                            return <div key={index} className='flex flex-col justify-between py-1 mt-2 bg-transparent relative'>
                                                <span className={"text-sm absolute top-2 left-3 " + 'text-plant'}>#{index + 1}</span>
                                                <input className={"w-full h-[58px] bg-primary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl "} placeholder={`Enter #${index + 1} note`} value={d.val} onChange={(e) => changeNote(e, index)} />
                                            </div>
                                        })
                                        : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {
                    saving
                        ? <div className="absolute inset-0 opacity-80 bg-primary"></div>
                        : null
                }
            </div >
            {/*footer*/}
            <div className="flex flex-row items-center gap-1 w-full px-8 py-3 sm:static fixed bottom-0 left-0">
                {
                    !saving
                        ? <div className='flex flex-grow bg-color4 rounded-lg font-bold uppercase justify-center text-sm py-3 text-primary outline-none focus:outline-none cursor-pointer shadow drop-shadow shadow-color4' onClick={() => handleAddContact()}>
                            Save
                        </div>
                        : <div className="flex flex-row items-center justify-center w-full h-full bg-transparent">
                            <span className="loader"></span>
                            <span className="px-2 text-xl">Saving Customer</span>
                        </div>
                }
            </div>
        </div >
    )
}