import PersonImage from '../../../Assets/img/user.png'
import { FcEditImage, FcInfo } from "react-icons/fc";
import { CiEdit, CiCircleInfo } from 'react-icons/ci'
import { AiFillDelete } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from "react";

export default function CustomerCard(props) {
    const navigate = useNavigate();
    const d = props.data

    let n = ''
    let namearr = d.name.split(' ')
    if (namearr.length > 1)
        n = namearr[0].substring(0, 1).toUpperCase() + namearr[namearr.length - 1].substring(0, 1).toUpperCase()
    else
        n = d.name.substring(0, 2).toUpperCase()
    return (
        <tr className={"relative justify-center align-middle bg-primary items-center my-3 rounded-5 p-4 z-1 "}>
            <td scope='row' className={'p-4 sticky left-0 h-fit content-fit bg-primary whitespace-nowrap cursor-pointer border-l ' + props.borderColor}>
                <div className="w-12 h-12 flex justify-center items-center text-green bg-secondary rounded-[50%] p-5 font-['Kristen_ITC']">
                    {
                        n
                    }
                </div>
            </td>
            <td scope='row' className='p-4 sticky left-1 h-fit content-fit bg-primary whitespace-nowrap cursor-pointer' onClick={() => navigate('/CompanyCustomerDetails', { state: d })}>
                {d.name}
            </td>
            <td className='p-4 whitespace-nowrap'>
                {d.phone}
            </td>
            <td className='p-4'>
                <label className='block whitespace-nowrap'>{d.email ? d.email : <span className='italic text-sm'>No email mentioned</span>}</label>
            </td>
            <td className='p-4'>
                <label className='block whitespace-nowrap'>{d.lastVisit ? d.lastVisit : <span className='italic text-sm'>no visit yet</span>}</label>
            </td>
            <td className='p-4'>
                <label className='block whitespace-nowrap'>{d.totalPurchase ? d.totalPurchase : 0}</label>
            </td>
            <td className='p-4'>
                <div className={'p-2 rounded-full w-1 h-1 ml-3 ' + (d.status === 1 ? ' bg-green' : 'bg-red')}></div>
            </td>
            <td className='p-4 place-items-center'>
                <div className='flex justify-center'>
                    <AiFillDelete className='bg-red mr-1 p-1 w-7 h-7 rounded' />
                </div>
            </td>
        </tr>
    )
}