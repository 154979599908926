import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { FcBusinessman, FcManager, FcReadingEbook, FcShop, FcDocument } from "react-icons/fc";

export default function CompanySide(props) {
    const navigate = useNavigate()

    const [dashboardFocus, setDashboardFocus] = useState('')
    const [usersFocus, setUsersFocus] = useState('')
    const [customerFocus, setCustomerFocus] = useState('')
    const [vendorFocus, setVendorFocus] = useState('')
    const [productFocus, setProductFocus] = useState('')

    const iconClass = 'w-6 h-6 xl:mr-2 text-center align-center items-center '
    const buttonClass = 'flex xl:flex-row flex-col p-2 xl:justify-start justify-center xl:items-start items-center xl:px-5 my-1 shadow drop-shadow hover:bg-color1 hover:shadow-color1 rounded'
    const labelClass = 'xl:block xl:text-md text-xs p-1 whitespace-nowrap'

    useEffect(() => {
        const loadPage = async () => {
            await setDashboardFocus('')
            await setUsersFocus('')
            await setCustomerFocus('')
            await setVendorFocus('')
            await setProductFocus('')

            switch (props.activeButton) {
                case 'dashboard': return setDashboardFocus('bg-color1')
                case 'companyusers': return setUsersFocus('bg-color1')
                case 'companycustomer': return setCustomerFocus('bg-color1')
                case 'companyvendor': return setVendorFocus('bg-color1')
                case 'companyproduct': return setProductFocus('bg-color1')
                default: return
            }
        }
        loadPage()
            .catch(console.error);
    }, [props.activeButton])

    return (
        <div className={'lg:block hidden bg-secondary left-0 top-0 h-full w-full text-color2 '}>
            <label className="xl:block hidden flex-grow italic p-3 whitespace-nowrap font-bold">POS System Management</label>
            <div className="flex flex-col xl:px-2 xl:pt-5 pt-16">
                <button className={buttonClass + " " + dashboardFocus} onClick={() => navigate('/Company')}>
                    <FcDocument className={iconClass} />
                    <span className={labelClass}>Dashoboard</span>
                </button>
                <button className={buttonClass + " " + usersFocus} onClick={() => navigate('/CompanyUsers')}>
                    <FcManager className={iconClass} />
                    <span className={labelClass}>Users</span>
                </button>
                <button className={buttonClass + " " + customerFocus} onClick={() => navigate('/CompanyCustomer')}>
                    <FcReadingEbook className={iconClass} />
                    <span className={labelClass}>Customer</span>
                </button>
                <button className={buttonClass + " " + vendorFocus} onClick={() => navigate('/CompanyVendor')}>
                    <FcBusinessman className={iconClass} />
                    <span className={labelClass}>Vendor</span>
                </button>
                <button className={buttonClass + " " + productFocus} onClick={() => navigate('/CompanyProduct')}>
                    <FcShop className={iconClass} />
                    <span className={labelClass}>Product</span>
                </button>
            </div>
        </div >
    )
}