import axios from "axios"

export let product = {
    type: -1,
    name: '',
    categoryid: -1,
    brandid: -1,
    barcode: -1,
    description: '',
    supplierid: -1,
    currentqty: 1,
    reorderqty: 1,
    alertqty: 1,
    alertnotif: false,
    wholesalepr: 0,
    retailpr: 0,
    specialpr: 0,
    specialper: 0,
    fromdate: null,
    todate: null,
    loyality: false,
    commision: false,
    createdby: sessionStorage.getItem('sgid'),
    createddate: new Date()
}
const url = process.env.REACT_APP_BASEURL
const token = process.env.REACT_APP_TOKEN

const config = {
    headers: {
        authorization: `bear ${token}`
    }
}

export let getproducts = (callback) => {
    try {
        axios.post(`${url}getproduct`, {}, config)
            .then((response) => {
                callback(response)
            })
            .catch((err) => {
                return err
            })
    } catch (err) {
        return err
    }
}