import React, { useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function LoginPage() {
    const theme = 'theme-light'
    const url = process.env.REACT_APP_BASEURL
    const token = process.env.REACT_APP_TOKEN
    const navigate = useNavigate()

    const [username, setUsername] = useState('')
    const [errUsername, setErrUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errPassword, setErrPassword] = useState('')

    const [error, SetError] = useState('')

    const Login = async () => {
        setErrPassword('')
        setErrUsername('')
        SetError('')

        let valid = true
        let errMsg = ''
        if (!username) {
            valid = false
            setErrUsername('* Required!')
        }
        if (!password) {
            valid = false
            setErrPassword('* Required!')
        }

        if (!valid)
            SetError(errMsg)
        else {
            axios.post(url + 'CheckUser',
                {
                    username,
                    password
                },
                {
                    headers: {
                        authorization: `bear ${token}`
                    }
                })
                .then((response) => {
                    response = response.data
                    if (response.result) {
                        sessionStorage.setItem('sgid', response.data.id)
                        sessionStorage.setItem("sgusername", response.data.username);
                        sessionStorage.setItem("sgstore", response.data.store);
                        navigate('/')
                    }
                    else {
                        SetError(response.err)
                    }

                }).catch(error => {
                    console.log(error.response.data);
                });
        }
    }

    useEffect(() => {
        sessionStorage.clear()
    }, [])

    return (
        <div className={theme + " flex items-center justify-center h-screen bg-primary"}>
            <div className="w-full max-w-xs">
                <div className="bg-secondary text-color5 shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2" htmlFor="username">
                            Username <span className='text-red text-sm'>{errUsername}</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline placeholder:italic placeholder:text-sm"
                            id="username"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-bold mb-2" htmlFor="password">
                            Password <span className='text-red text-sm'>{errPassword}</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline placeholder:italic placeholder:text-sm"
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                        />
                    </div>
                    <div className='mb-6'>
                        <span className='text-red text-sm'>{error}</span>
                    </div>
                    <button className="bg-color4 w-full hover:bg-color4 text-white font-bold py-2 px-4 rounded-full animate-pulse" onClick={Login}>
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};