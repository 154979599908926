import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useGlobalState } from "../../Globals/Values"
import { HiOutlineMenu } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import { FcBusinessman, FcManager, FcReadingEbook, FcShop, FcDocument } from "react-icons/fc";

export default function CompanyMobileHead(props) {
    const navigate = useNavigate()
    const [showMobile, setShowMobile] = useGlobalState('showMobile')
    const [menuIcon, setMenuIcon] = useGlobalState('menuIcon')

    const [dashboardFocus, setDashboardFocus] = useState('')
    const [usersFocus, setUsersFocus] = useState('')
    const [customerFocus, setCustomerFocus] = useState('')
    const [vendorFocus, setVendorFocus] = useState('')
    const [productFocus, setProductFocus] = useState('')

    const iconClass = 'w-6 h-6 float-left mr-2'
    const labelClass = 'text-md p-1 whitespace-nowrap'

    const showMobileMenu = async () => {
        if (showMobile) {
            setShowMobile(false)
            setMenuIcon(<HiOutlineMenu size={25} />)
        }
        else {
            setShowMobile(true)
            setMenuIcon(<AiOutlineClose size={25} />)
        }
    }

    useEffect(() => {
        setDashboardFocus('')
        setUsersFocus('')
        setCustomerFocus('')
        setVendorFocus('')
        setProductFocus('')

        setMenuIcon(<HiOutlineMenu size={25} />)
        switch (props.activeButton) {
            case 'dashboard': return setDashboardFocus('bg-color1')
            case 'users': return setUsersFocus('bg-color1')
            case 'companycustomer': return setCustomerFocus('bg-color1')
            case 'companyvendor': return setVendorFocus('bg-color1')
            case 'companyproduct': return setProductFocus('bg-color1')
            default: return
        }
    }, [])

    return (
        <div className="fixed bg-secondary w-full left-0 top-14 z-10">
            <div className="fixed bg-secondary w-full flex flex-col z-10">
                <button className={"p-3 px-5 my-1 shadow drop-shadow hover:bg-color1 hover:shadow-color1 rounded " + dashboardFocus} onClick={() => { setShowMobile(false); navigate('/Company') }}>
                    <FcDocument className={iconClass} />
                    <span className={labelClass}>Dashoboard</span>
                </button>
                <button className={"p-3 px-5 my-1 shadow drop-shadow hover:bg-color1 hover:shadow-color1 rounded " + usersFocus} onClick={() => { setShowMobile(false); navigate('/CompanyUsers') }}>
                    <FcManager className={iconClass} />
                    <span className={labelClass}>Users</span>
                </button>
                <button className={"p-3 px-5 my-1 shadow drop-shadow hover:bg-color1 hover:shadow-color1 rounded " + customerFocus} onClick={() => { setShowMobile(false); navigate('/CompanyCustomer') }}>
                    <FcReadingEbook className={iconClass} />
                    <span className={labelClass}>Customer</span>
                </button>
                <button className={"p-3 px-5 my-1 shadow drop-shadow hover:bg-color1 hover:shadow-color1 rounded " + vendorFocus} onClick={() => { setShowMobile(false); navigate('/CompanyVendor') }}>
                    <FcBusinessman className={iconClass} />
                    <span className={labelClass}>Vendor</span>
                </button>
                <button className={"p-3 px-5 my-1 shadow drop-shadow hover:bg-color1 hover:shadow-color1 rounded " + productFocus} onClick={() => { setShowMobile(false); navigate('/CompanyProduct') }}>
                    <FcShop className={iconClass} />
                    <span className={labelClass}>Product</span>
                </button>
            </div>
            <div className="fixed top-20 inset-0 bg-primary opacity-75 z-1 w-full h-screen" onClick={() => { showMobileMenu() }}></div>
        </div>
    )
}