
import { BsCheck, BsX } from 'react-icons/bs'
import { AiFillEye } from 'react-icons/ai'
import { useEffect, useState } from 'react'
import axios from "axios"

export default function PlanRequest(props) {
    const url = process.env.REACT_APP_BASEURL
    const trStyle = 'p-3 text-left align-middle border border'
    const tdStyle = 'p-3 align-middle text-left whitespace-nowrap'

    const [requestList, setRequestList] = useState([])

    const getRequest = async () => {
        await axios.get(url + 'RequestList')
            .then(async (response) => {
                response = response.data
                if (response.result) {
                    await setRequestList(response.data)
                }
            })
    }

    const upgradeRequest = async (id, store, newplan, status) => {
        await axios.post(url + 'ApproveRequest', {
            id, store, newplan, status
        })
            .then(async (response) => {
                response = response.data
                if (response.result) {
                    getRequest()
                }
            })
    }

    useEffect(() => {
        getRequest()
    }, [])

    return (
        <div className='w-full'>
            {/* <div className="justify-end m-1">
                <PlanNew />
            </div> */}

            <div className='flex overflow-x-auto p-1 w-full'>
                <table className="table-auto border-separate lg:border-spacing-2 border-spacing-0 overflow-scroll-auto w-full">
                    <thead>
                        <tr className={trStyle + ' bg-green'}>
                            <th className={tdStyle}>Company</th>
                            <th className={tdStyle}>Previous Plan</th>
                            <th className={tdStyle}>Requested Plan</th>
                            <th className={tdStyle}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            requestList ? requestList.map((d, index) => {
                                return <tr key={index} className={trStyle + (index % 2 === 0 ? ' bg-secondary lg:bg-primary' : ' bg-primary')}>
                                    <td className={tdStyle}>{d.storename} <button className="hidden lg:block bg-transparent p-1 rounded text-color4 hover:text-color3 float-right"><AiFillEye size={15} /></button></td>
                                    <td className={tdStyle}>{d.oldplanname}</td>
                                    <td className={tdStyle}>{d.newplanname}</td>
                                    <td className={tdStyle}>
                                        {
                                            d.status === 0 ?
                                                <div>
                                                    <button className="bg-green p-1 m-1 rounded hover:scale-105"><BsCheck size={15} onClick={() => upgradeRequest(d.id, d.store, d.newplan, 1)} /></button>
                                                    <button className="bg-red p-1 m-1 rounded hover:scale-105"><BsX size={15} onClick={() => upgradeRequest(d.id, d.store, d.newplan, 0)} /></button>
                                                </div>
                                                : <span className='bg-plant p-1 px-2 rounded-md text-primary italic'>Upgraded</span>
                                        }
                                    </td>
                                </tr>
                            })
                                : null
                        }
                    </tbody>
                </table>
            </div>
        </div >
    )
}