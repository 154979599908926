import UserCard from "./UserCard"

export default function UserView(props) {

    return (
        <div className='bg-secondary p-2 min-h-96'>
            <div className="justify-end m-1">
                {/* <UserNew /> */}
            </div>

            <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 justify-between pt-2 w-full'>
                <UserCard name='nicolas' userRole='Administrator' />
                <UserCard name='micheal' userRole='Accountant' />
                <UserCard name='abdallah' userRole='Cashier' />
                <UserCard name='ali' userRole='Cashier' />
            </div>
        </div>
    )
}