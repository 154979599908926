import BackButton from "../../Admin/Controls/BackButton"
import { IoMdArrowDropdown } from 'react-icons/io'
import { useState } from "react"

export default function MembershipNew(props) {
    const [currentQty, setCurrentQty] = useState(1)
    const [alertQty, setAlertQty] = useState(1)
    const [reorderQty, setReorderQty] = useState(1)

    const [errDisountPer, setErrDiscountPer] = useState('')

    return (
        <div className='flex flex-col w-full lg:px-[10%] px-0'>
            <div className='flex flex-row items-center py-2'>
                <BackButton />
                <div className="italic text-xl text-color8">
                    Add Membership
                </div>
            </div>
            <div className="grid lg:grid-cols-2 grid-row lg:gap-3 gap-5 bg-primary pt-4 lg:p-4">
                <div className="flex flex-col">
                    <div className="flex flex-col px-4 shadow drop-shadow shadow-color5 py-2 relative">
                        <span className="absolute w-fit bg-primary text-xl text-color5 px-2 italic -top-4 right-6 font-bold">Basic Info</span>
                        <div className="flex flex-col justify-between py-1 mt-6 bg-transparent relative">
                            <span className="text-plant text-sm absolute left-3 z-10">Membership Name</span>
                            <input className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a membership name" />
                        </div>
                        <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                            <span className="text-plant text-sm absolute left-3 z-10">Membership Description</span>
                            <textarea className="w-full h-[82px] bg-secondary focus:bg-black outline-none py-1 pt-5 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Write a description about this package" rows={3} style={{ resize: "none" }} />
                        </div>
                    </div>
                    <div className="flex flex-col px-4 shadow drop-shadow shadow-color5 py-2 mt-6 relative">
                        <span className="absolute w-fit bg-primary text-xl text-color5 px-2 italic -top-4 right-6 font-bold">Additional Info</span>
                        <div className="flex flex-col justify-between py-1 mt-6 bg-transparent relative">
                            <span className="text-plant text-sm absolute left-3 z-10">Note to remember</span>
                            <textarea className="w-full  bg-secondary focus:bg-black outline-none py-1 pt-5 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a note to remmber about this package" rows={6} style={{ resize: "none" }} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-col px-4 shadow drop-shadow shadow-color5 py-2 relative">
                        <span className="absolute w-fit bg-primary text-xl text-color5 px-2 italic -top-4 right-6 font-bold">Services & Sessions</span>

                        <div className="flex flex-col justify-between py-1 mt-6 bg-transparent relative">
                            <span className="text-plant text-sm absolute left-3 z-10">Services included</span>
                            <input className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Add included services" readOnly />
                            <IoMdArrowDropdown className="absolute inset-y-0 right-5 translate-y-1/2 flex items-center cursor-pointer text-plant" size={33} />
                        </div>
                        <div className="flex flex-col justify-between py-1 mt-6 bg-transparent relative">
                            <span className="text-plant text-sm absolute left-3 z-10"># of sessions</span>
                            <input className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Choose a number of session" type={'number'} min={1} />
                            {/* <IoMdArrowDropdown className="absolute inset-y-0 right-5 translate-y-1/2 flex items-center cursor-pointer text-plant" size={33} /> */}
                        </div>
                        <div className="flex flex-col justify-between py-1 mt-6 bg-transparent relative">
                            <span className="text-plant text-sm absolute left-3 z-10">Valid for</span>
                            <input className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Choose a number of session" type={'date'} />
                            {/* <IoMdArrowDropdown className="absolute inset-y-0 right-5 translate-y-1/2 flex items-center cursor-pointer text-plant" size={33} /> */}
                        </div>
                    </div>
                    <div className="flex flex-col px-4 shadow drop-shadow shadow-color5 py-2 mt-6 relative">
                        <span className="absolute w-fit bg-primary text-xl text-color5 px-2 italic -top-4 right-6 font-bold">Price</span>
                        <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                            <span className="text-plant text-sm absolute left-3 z-10">Price</span>
                            <input className="w-full h-[58px] bg-secondary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a membership price" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col p-4 bg-primary">
                <button className="bg-color4 rounded-md py-2 text-xl cursor-pointer text-color5 hover:opacity-60">Save</button>
            </div>
        </div>
    )
}