import ProductCard from "./ProductCard"
import BackButton from '../../Admin/Controls/BackButton'
import { HiOutlineSearch, HiFilter } from 'react-icons/hi'
import { TfiExport } from 'react-icons/tfi'
import { ImSortAmountAsc } from 'react-icons/im'
import { GrFormAdd } from 'react-icons/gr'
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import ChooseProductType from "./ChooseProductType"
import { useGlobalState } from "../../../Globals/GlobalStates"
import { getproducts, product } from '../../../Model/ProductModel'

export default function ProductList(props) {
    const [showModal, setShowModal] = useGlobalState('openProductAll');
    const [showprogress, setshowprogress] = useState(true)
    const navigate = useNavigate()
    const [proType, setProType] = useState('All')
    const [productlist, setproductlist] = useState([])

    const openAdd = async () => {
        if (proType === 'Product')
            navigate('/CompanyProductNew')
        else if (proType === 'Services')
            navigate('/CompanyServiceNew')
        else if (proType === 'Package')
            navigate('/CompanyPackageNew')
        else if (proType === 'Membership')
            navigate('/CompanyMembershipNew')
        else if (proType === 'All')
            setShowModal(true)
    }

    useEffect(() => {
        getproducts(async (response) => {
            await setshowprogress(true)
            await setproductlist(response.data.data)
            await setshowprogress(false)
        })
    }, [])

    return (
        <div className='flex flex-col w-full bg-secondary relative'>
            <ChooseProductType />
            <div className='flex flex-row items-center py-2'>
                <BackButton />
                <div className="italic">
                    Dashboard * Product
                </div>
            </div>
            <div className='w-full flex flex-row overflow-x-auto justify-between items-center gap-0 shadow shadow-plant'>
                <button className='flex-1 bg-primary text-color8 focus:bg-plant focus:text-primary hover:bg-plant hover:text-primary font-bold text-sm px-2 py-5 whitespace-nowrap' onClick={() => setProType('All')}>
                    All <span className="italic">(400)</span>
                </button>
                <button className='flex-1 bg-primary text-color8 focus:bg-plant focus:text-primary hover:bg-plant hover:text-primary font-bold text-sm px-2 py-5 whitespace-nowrap' onClick={() => setProType('Product')}>
                    Product
                </button>
                <button className='flex-1 bg-primary text-color8 focus:bg-plant focus:text-primary hover:bg-plant hover:text-primary font-bold text-sm px-2 py-5 whitespace-nowrap' onClick={() => setProType('Services')}>
                    Services
                </button>
                <button className='flex-1 bg-primary text-color8 focus:bg-plant focus:text-primary hover:bg-plant hover:text-primary font-bold text-sm px-2 py-5 whitespace-nowrap' onClick={() => setProType('Package')}>
                    Package
                </button>
                <button className='flex-1 bg-primary text-color8 focus:bg-plant focus:text-primary hover:bg-plant hover:text-primary font-bold text-sm px-2 py-5 whitespace-nowrap' onClick={() => setProType('Membership')}>
                    Membership
                </button>
                <button className='hidden flex-1 bg-primary text-color8 focus:bg-plant focus:text-primary hover:bg-plant hover:text-primary font-bold text-sm px-2 py-5 whitespace-nowrap' onClick={() => setProType('Production')}>
                    Production
                </button>
            </div>
            <div className="flex lg:flex-row flex-col pt-4 items-center">
                <div className="lg:basis-3/3 w-full lg-mb-0 mb-2 flex-grow lg:px-0 px-1">
                    <div className="w-full relative">
                        <input className="bg-primary py-2 px-2 pr-8 border border-color5 w-full" placeholder="Search a Product" />
                        <div className="absolute right-2 bottom-0 -translate-y-1/2">
                            <HiOutlineSearch size={20} />
                        </div>
                    </div>
                </div>
                <div className="flex w-full h-full items-center lg:justify-end justify-start px-1">
                    <div className="flex items-center py-2 flex-grow lg:justify-end lg:px-5 text-color8">
                        <span className="lg:px-5 px-2 italic text-color8">10 item(s)</span>
                    </div>
                    <div className="flex items-center py-2 px-5 bg-primary text-color8 hover:text-primary hover:bg-color8 cursor-pointer">
                        <span className="px-1 hidden">Filter</span>
                        <HiFilter size={20} />
                    </div>
                    <div className="flex items-center py-2 px-5 bg-primary text-color8 hover:text-primary hover:bg-color8 cursor-pointer">
                        <span className="px-1 hidden">Sort By</span>
                        <ImSortAmountAsc size={20} />
                    </div>
                    <div className="flex items-center py-2 px-5 bg-primary text-white hover:text-primary hover:bg-color8 cursor-pointer">
                        <TfiExport size={20} />
                    </div>
                </div>
                <div className="w-full flex justify-end lg:static fixed bottom-5 right-5 z-10">
                    <div className="flex flex-row bg-gradient-to-r from-color4 to-plant p-5 lg:px-16 lg:py-2 items-center text-primary font-bold lg:rounded rounded-[50%] cursor-pointer" onClick={() => openAdd()}>
                        <GrFormAdd size={30} />
                        <span className="lg:block hidden">Add Product</span>
                    </div>
                </div>
            </div>
            <div className='w-full grid 2xl:grid-cols-5 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 lg:gap-4 gap-1 mt-5 p-2'>
                {
                    productlist
                        ? productlist.map((x, index) => {
                            return <ProductCard key={index} item={x} />
                        })
                        : null
                }
            </div>

            {
                showprogress
                    ? <span className="m-auto p-auto items-center  loader z-10"></span>
                    : null
            }
        </div>
    )
}