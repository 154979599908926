import { useEffect, useState, useLayoutEffect } from "react"
import { IoMdArrowDropdown, IoMdAddCircleOutline } from 'react-icons/io'
import { BiSearch } from 'react-icons/bi'
import axios from 'axios'

export default function ContactStaffModel(props) {
    const url = process.env.REACT_APP_BASEURL
    const token = process.env.REACT_APP_TOKEN
    const [showModal, setShowModal] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [stafflist, setstafflist] = useState([])
    const [staffname, setstaffname] = useState('')
    const [errstaffname, seterrstaffname] = useState('')
    const [refresh, setRefresh] = useState(true)
    const [searchKey, setSearchKey] = useState('')

    const handleChangeStaff = async (event) => {
        await props.onChange(event)
        await setShowModal(false)
    }

    const handleChangeShowStaff = async () => {
        await setShowModal(!showModal)
        // await props.showValue(!showModal)
    }

    const getStaffs = async () => {
        try {
            // axios.post(url + 'getBrands', { storeid: sessionStorage.getItem('sgstore') }, {
            //     headers: {
            //         authorization: `bear ${token}`
            //     }
            // })
            //     .then(async (response) => {
            //         if (response.status === 200) {
            //             await setstafflist(response.data.result)
            //             await setRefresh(false)
            //         }
            //     })
            //     .catch((err) => {
            //         console.log(err)
            //     })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (refresh) {
            getStaffs()
        }
    }, [refresh])

    useLayoutEffect(() => {
        if (showModal) {
            // Get original body overflow
            const originalStyle = window.getComputedStyle(document.body).overflow;
            // Prevent scrolling on mount
            document.body.style.overflow = "hidden";
            // Re-enable scrolling when component unmounts
            return () => (document.body.style.overflow = originalStyle);
        }
    }, [showModal]); // Empty array ensures effect is only run on mount and unmount

    return (
        <div className="cursor-default">
            <div className="flex flex-col justify-between py-1 mt-2 bg-transparent cursor-pointer relative " onClick={() => handleChangeShowStaff()}>
                <span className="text-plant text-sm absolute top-2 left-3">Favorite Staff</span>
                <input className="w-full h-[58px] bg-primary focus:bg-black  outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl cursor-pointer " placeholder="Choose a favorite staff" value={props.value.name} readOnly />
                <IoMdArrowDropdown className="absolute inset-y-0 right-5 translate-y-1/2 flex items-center cursor-pointer text-plant" size={33} />
            </div>
            {
                showModal ? (
                    <div className="fixed w-full z-20">
                        <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
                            <div className="relative flex-grow w-auto mt-20 lg:mx-auto mx-0 max-w-md rounded shadow drop-shadow-xl z-50 border border-secondary">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-primary border border-secondary outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-center p-5 rounded-t">
                                        <h3 className="flex-grow text-xl font-semibold text-color5">
                                            Select a Staff
                                        </h3>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="m-auto cursor-pointer w-5 h-5 text-color5" onClick={() => setShowModal(false)}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>

                                    </div>
                                    {/*body*/}
                                    <div className="flex-auto px-5 pb-3">
                                        <div className='relative py-2'>
                                            <input className="pl-10 bg-secondary w-full p-2 placeholder:italic rounded focus:border focus:border-color5 outline-none" placeholder='Search a Staff' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                            <div className='absolute top-1/2 -translate-y-1/2 left-2'>
                                                <BiSearch size={25} />
                                            </div>
                                        </div>
                                        {/* <div className='text-color4 px-2 cursor-pointer py-2'>
                                            {
                                                showAdd
                                                    ? <div className='flex'>
                                                        <input className="bg-secondary w-full p-2 placeholder:italic rounded focus:border focus:border-color5 outline-none text-color5" placeholder='New brand name' value={brandname} onChange={(e) => { setbrandNameErr(''); setbrandName(e.target.value) }} />
                                                        <div className='flex justify-end p-2 gap-1'>
                                                            <button className='w-full text-primary p-1 bg-green font-bold rounded-full' onClick={() => addBrand()}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                                </svg>
                                                            </button>
                                                            <button className='w-full text-primary p-1 bg-red font-bold rounded-full' onClick={() => setShowAdd(false)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    : <div className='relative flex text-color7' onClick={() => setShowAdd(!showAdd)}>
                                                        <div className='flex-grow'>
                                                            <span className="text-color7 underiline">Add new Brand</span>
                                                            <span className="text-md text-color7 italic px-1">{brandnameerr}</span>
                                                        </div>
                                                        <div className=''>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                            }
                                        </div> */}
                                        <div className='flex flex-col p-2'>
                                            {
                                                stafflist && stafflist.length > 0
                                                    ? stafflist.map((d, index) => {
                                                        return <div key={d.id} className={'p-3 bg-secondary shadow shadow-md shadow-secondary my-0.5 cursor-pointer ' + (d.name.toLowerCase().includes(searchKey.toLowerCase()) ? '' : 'hidden')} onClick={() => handleChangeStaff(d)}>
                                                            <span className="font-bold text-color5 ">{d.name}</span>
                                                        </div>
                                                    })
                                                    : <div className='text-center text-sm text-color5 italic'>No Staffs to show</div>
                                            }
                                        </div>
                                    </div >
                                </div>
                            </div>
                            <div className="fixed inset-0 z-40 bg-secondary" onClick={() => setShowModal(false)}></div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}