import { Navigate, Outlet, useNavigate } from "react-router-dom";
import React, { Component } from "react";

const useAuth = () => {
    const username = sessionStorage.getItem('sgusername')
    var auth = false;

    if (username && username !== 'undefined') {
        auth = true;
    }

    const user = { authenticated: auth };
    return user.authenticated;
}

const Authenticated = () => {
    const navigate = useNavigate();
    const isAuth = useAuth();

    return isAuth ? <Outlet /> : <Navigate to="/Login" />;
}

export default Authenticated;