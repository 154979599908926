

export default function Required() {

    return (
        <div class="bg-red absolute -bottom-9 min-w-[100px] p-2 px-3 z-10 rounded-md text-primary">
            This field is required
            <div
                class="left-[39px] absolute top-0 transform translate-x-1/2 -translate-y-1/2 rotate-45 w-4 h-4 bg-red border-l border-t border-secondary  z-1"
            ></div>
        </div>
    )
}