import { useNavigate } from "react-router-dom"
import { IoArrowBack } from 'react-icons/io5'

export default function BackButton(props) {
    const navigate = useNavigate();

    return (
        <button className="p-1 bg-transparent hover:bg-color5 border-0 text-color8 text-md font-semibold outline-none focus:outline-none" onClick={() => navigate(-1)}>
            <IoArrowBack size={20} />
        </button>
    )
}