import BackButton from "../../Admin/Controls/BackButton";
import PersonImage from '../../../Assets/img/user.png'
import { useLocation } from "react-router-dom";
import { BsFillTelephoneFill, BsFillPersonFill } from 'react-icons/bs'
import { IoIosArrowForward } from 'react-icons/io'
import { MdEmail } from 'react-icons/md'
import { useState } from "react";
import Activities from "./Activities";
import PersonalInfo from "./PersonalInfo";
import Appointments from "./Appointments";
import Notes from "./Notes";
import Visits from "./Visits";
import { Transition } from '@headlessui/react'
import Review from "./Review";
import { format } from 'date-fns'

export default function ContactDetails(props) {
    const { state } = useLocation();
    const [showDiv, setShowDiv] = useState(1)
    const [emptyEmail, setEmptyEmail] = useState(false)
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 100

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) {
            if (showDiv >= 1 && showDiv < 8)
                setShowDiv(showDiv + 1)
        }
        else if (isRightSwipe) {
            if (showDiv > 1 && showDiv <= 8)
                setShowDiv(showDiv - 1)
        }
        console.log(showDiv)
    }

    return (
        props.contactType
            ? <div className="flex flex-col w-full bg-secondary p-2">
                <div className="flex flex-row items-center">
                    <BackButton />
                    <span className="px-1 text-color5">Dashboard</span>
                    <span className="px-1 text-color5">*</span>
                    <span className="px-1 text-color5">Customer Info</span>
                </div>
                <div className="w-full lg:p-5 p-0">
                    <div className="flex flex-col shadow drop-shadow shadow-2xl py-5 lg:px-3 px-0">
                        <div className="flex lg:flex-row flex-col w-full lg:p-5 p-0 my-2 shadow drop-shadow shadow-2xl bg-primary">
                            <div className="flex flex-shrink justify-center flex-row p-2">
                                <div className="w-56 h-56 flex justify-center items-center text-green bg-secondary rounded-[50%] p-5 text-8xl font-['Kristen_ITC']">
                                    {state.name.substring(0, 2).toUpperCase()}
                                </div>
                            </div>
                            <div className="lg:flex-grow flex lg:flex-row flex-col lg:justify-between justify-start w-full py-2 lg:pl-20">
                                <div className="flex flex-col justify-between">
                                    <div className="flex flex-col">
                                        <div className="flex flex-shrink flex-row items-center p-2">
                                            <BsFillPersonFill className="text-plant" size={20} />
                                            <div className="flex flex-shrink w-fit font-bold text-white px-2 py-0 rounded text-xl">{state.name}</div>
                                            {/* <div className="flex flex-shrink w-fit px-2 py-1"></div> */}
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="flex flex-shrink flex-row items-center p-2">
                                            <BsFillTelephoneFill className="text-plant" size={20} />
                                            <div className="flex flex-shrink w-fit font-bold text-white px-2 py-0 rounded text-sm">{state.phone}</div>
                                            {/* <div className="flex flex-shrink w-fit px-2 py-1"></div> */}
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="flex flex-shrink flex-row items-center p-2">
                                            < MdEmail className="text-plant" size={20} />
                                            <div className="flex flex-shrink w-fit font-bold text-white px-2 py-0 rounded text-sm">
                                                {state.email
                                                    ? state.email
                                                    : <div className="flex flex-row items-center">
                                                        {
                                                            emptyEmail
                                                                ? <input className="bg-secondary px-2 py-1" />
                                                                : <span className="text-color7 text-md italic hover:underline cursor-pointer" onClick={() => setEmptyEmail(true)}>Add Email</span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            {/* <div className="flex flex-shrink w-fit px-2 py-1"></div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col lg:justify-between justify-start whitespace-nowrap">
                                    <div className="flex flex-shrink flex-row lg:justify-start justify-between items-center p-2 w-full">
                                        <div className="flex w-fit font-bold text-color5 rounded w-full lg:w-[50%] rounded px-1 lg:px-8 mr-2 text-sm">Total Visit</div>
                                        <div className="flex flex-shrink w-fit font-bold text-primary px-2 py-0 rounded bg-plant text-sm">{state.totalvisit ? state.totalvisit : 0}</div>
                                    </div>
                                    <div className="flex flex-shrink flex-row lg:justify-start justify-between items-center p-2 w-full">
                                        <div className="flex w-fit font-bold text-color5 rounded w-full lg:w-[50%] rounded px-1 lg:px-8 mr-2 text-sm">Total Purchase</div>
                                        <div className="flex flex-shrink w-fit font-bold text-primary px-2 py-0 rounded bg-plant text-sm">USD {state.totalpurchase ? state.totalpurchase : 0}</div>
                                    </div>
                                    <div className="flex flex-shrink flex-row lg:justify-start justify-between items-center p-2 w-full">
                                        <div className="flex w-fit font-bold text-color5 rounded w-full lg:w-[50%] rounded px-1 lg:px-8 mr-2 text-sm">Created date</div>
                                        <div className="flex flex-shrink w-fit font-bold text-primary px-2 py-0 rounded bg-plant text-sm">{format(new Date(state.createddate), 'yyyy-MM-dd')}</div>
                                    </div>
                                </div>
                                <div className="">

                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row lg:justify-center justify-start overflow-x-auto w-full lg:p-0 p-1">
                            <div className={"whitespace-nowrap cursor-pointer px-3 py-2 m-1 rounded hover:opacity-100 text-sm " + (showDiv === 1 ? 'bg-plant text-primary font-bold' : 'bg-primary')} onClick={() => setShowDiv(1)}>Activities</div>
                            <div className={"whitespace-nowrap cursor-pointer px-3 py-2 m-1 rounded hover:opacity-100 text-sm " + (showDiv === 2 ? 'bg-plant text-primary font-bold' : 'bg-primary')} onClick={() => setShowDiv(2)}>Personal Info</div>
                            <div className={"whitespace-nowrap cursor-pointer px-3 py-2 m-1 rounded hover:opacity-100 text-sm " + (showDiv === 3 ? 'bg-plant text-primary font-bold' : 'bg-primary')} onClick={() => setShowDiv(3)}>Visits</div>
                            <div className={"whitespace-nowrap cursor-pointer px-3 py-2 m-1 rounded hover:opacity-100 text-sm " + (showDiv === 4 ? 'bg-plant text-primary font-bold' : 'bg-primary')} onClick={() => setShowDiv(4)}>Sales</div>
                            <div className={"whitespace-nowrap cursor-pointer px-3 py-2 m-1 rounded hover:opacity-100 text-sm " + (showDiv === 5 ? 'bg-plant text-primary font-bold' : 'bg-primary')} onClick={() => setShowDiv(5)}>Appointments</div>
                            <div className={"whitespace-nowrap cursor-pointer px-3 py-2 m-1 rounded hover:opacity-100 text-sm " + (showDiv === 6 ? 'bg-plant text-primary font-bold' : 'bg-primary')} onClick={() => setShowDiv(6)}>Photos</div>
                            <div className={"whitespace-nowrap cursor-pointer px-3 py-2 m-1 rounded hover:opacity-100 text-sm " + (showDiv === 7 ? 'bg-plant text-primary font-bold' : 'bg-primary')} onClick={() => setShowDiv(7)}>Notes</div>
                            <div className={"whitespace-nowrap cursor-pointer px-3 py-2 m-1 rounded hover:opacity-100 text-sm " + (showDiv === 8 ? 'bg-plant text-primary font-bold' : 'bg-primary')} onClick={() => setShowDiv(8)}>Review</div>
                        </div>
                        <div className="transition-all animate-fade" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                            {
                                showDiv === 1
                                    ? <Activities data={state} />
                                    : showDiv === 2
                                        ? <PersonalInfo data={state} />
                                        : showDiv === 3
                                            ? <Visits data={state} />
                                            : showDiv === 4
                                                ? <>Sales</>
                                                : showDiv === 5
                                                    ? <Appointments data={state} />
                                                    : showDiv === 6
                                                        ? <>Photos</>
                                                        : showDiv === 7
                                                            ? <Notes id={state.id} />
                                                            : showDiv === 8
                                                                ? <Review data={state} />
                                                                : null

                            }
                        </div>
                    </div>

                </div>
            </div >
            : <div className="">

            </div>
    )
}