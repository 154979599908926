import { useNavigate } from 'react-router-dom'
import Image from '../../../Assets/img/no-image.png'

export default function ProductCard(props) {
    const navigate = useNavigate()
    const data = props.item

    return (
        <div className=" bg-primary shadow drop-shadow flex flex-col relative cursor-pointer lg:mb-0 mb-3" onClick={() => navigate('/CompanyProductDetail', { state: data })}>
            <div className='flex  h-36 p-0 justify-center'>
                <img src={data.photo ? data.photo : Image} className='w-[100%] h-36' alt='no-image' />
            </div>
            <div className='w-full h-full flex-grow flex flex-col p-0'>
                <div className='font-bold text-xl text-center absolute -top-4 -right-1 p-0'>
                    <span className='px-3 bg-color7 text-primary text-sm rounded'>{data.type == 1 ? 'Product' : null}</span>
                </div>
                <div className='font-bold text-xl px-2 py-1 text-color8'>
                    {data.name}
                </div>
                <div className='px-2 py-1 truncate text-sm italic text-color5 -mt-3'>
                    {data.description}
                </div>
                <div className='px-2 py-1 text-color8 text-sm text-right'>
                    <span className='text-lg font-bold'>{data.currentqty}</span>
                    <span className='italic px-2'>in stock</span>
                </div>
                {
                    data.featured
                        ? <div className='flex lg:flex-row flex-col px-2 py-1 text-right text-lg whitespace-nowrap lg:items-center justify-end'>
                            <span className='text-color5 px-2 line-through text-sm'>{data.wholesaleprice}</span>
                            <span className='text-plant px-2'>{data.wholesaleprice}</span>
                        </div>
                        : <div className='flex flex-row px-2 py-1 text-right text-lg whitespace-nowrap items-center justify-end'>
                            <span className='text-plant px-2'>LBP {data.wholesaleprice}</span>
                        </div>
                }

            </div>
        </div>
    )
}