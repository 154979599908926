import BackButton from "../../Admin/Controls/BackButton"
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdAddAPhoto } from 'react-icons/md'
import { BiPlus, BiMinus } from 'react-icons/bi'
import { useEffect, useRef, useState, useLayoutEffect } from "react"
import Toggle from '../../utils/Toggle'
import Category from "./Groups/Category"
import Brand from "./Groups/Brand"
import Supplier from "./Groups/Supplier"
import axios from "axios"

export default function ProductNew(props) {
    const url = process.env.REACT_APP_BASEURL
    const token = process.env.REACT_APP_TOKEN

    const [errDisountPer, setErrDiscountPer] = useState('')

    const [name, setName] = useState('')
    const [errName, setErrName] = useState(false)

    const [barcode, setbarcode] = useState('')
    const [errbarcode, seterrbarcode] = useState(false)

    const [description, setdescription] = useState('')
    const [errdescription, seterrdescription] = useState(false)

    const [currentQty, setCurrentQty] = useState(1)
    const [alertQty, setAlertQty] = useState(1)
    const [reorderQty, setReorderQty] = useState(1)
    const [alertnot, setalertnot] = useState(false)

    const [wholesalepr, setwholesalepr] = useState(0)
    const [retailpr, setretailpr] = useState(0)
    const [specialpr, setspecialpr] = useState(0)
    const [specialper, setspecialper] = useState(0)

    const [fromdate, setfromdate] = useState('')
    const [todate, settodate] = useState('')

    const [loyality, setloyality] = useState(false)
    const [commision, setcommision] = useState(false)

    const categoryForm = useRef()
    const [category, setCategory] = useState({ id: -1, name: '', store: -1, createdby: -1, createddate: '' })
    const [brand, setBrand] = useState({ id: -1, name: '', store: -1, createdby: -1, createddate: '' })
    const [supplier, setSupplier] = useState({ id: -1, name: '', store: -1, createdby: -1, createddate: '' })

    const handleChangeCategory = (e) => {
        setCategory(e)
    }

    const handleChangeBrand = (e) => {
        setBrand(e)
    }

    const handleChangeSupplier = (e) => {
        setSupplier(e)
    }
    const [brandShown, setbrandshown] = useState(false)
    const handleChangeShowBrand = async (e) => {
        // document.body.style.overflow = 'hidden';
        // document.body.style.overflow = 'unset';
        // console.log(e)
    }

    const addProduct = () => {
        try {
            axios.post(url + 'addproduct',
                {
                    type: 1,
                    name,
                    categoryid: category.id,
                    brandid: brand.id,
                    barcode,
                    description,
                    supplierid: supplier.id,
                    currentqty: currentQty,
                    reorderqty: reorderQty,
                    alertqty: alertQty,
                    alertnotif: alertnot,
                    wholesalepr,
                    retailpr,
                    specialpr,
                    specialper,
                    fromdate,
                    todate,
                    loyality,
                    commision,
                    createdby: sessionStorage.getItem('sgid'),
                    createddate: new Date()
                },
                {
                    headers: {
                        authorization: `bear ${token}`
                    }
                })
                .then((response) => {
                    console.log(response.data)
                })
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div id="product-div" className='prevent-select lg:mx-auto border-none rounded-lg shadow-lg shadow-primary relative grid grid-rows bg-secondary border border-primary outline-none focus:outline-none cursor-default'>
            <div className="flex flex-row border-b border-color5 relative justify-start items-center p-5 rounded-t w-full">
                <div className="flex justify-center items-center">
                    <BackButton />
                </div>
                <div className="flex justify-center items-center">
                    <h3 className="text-xl font-semibold px-5">
                        Add Product
                    </h3>
                </div>
                {
                    // saving
                    //     ? <div className="absolute inset-0 opacity-80 bg-primary"></div>
                    //     : null
                }
            </div>
            <div className="grid lg:grid-cols-2 grid-row lg:gap-3 gap-5 bg-secondary lg:p-4 mt-3 z-1">
                <div className="relative grid grid-cols px-4 shadow shadow-color5 py-2">
                    <span className="absolute w-fit bg-secondary text-xl text-color5 px-2 italic -top-4 right-6 font-bold">Basic Info</span>
                    <div className="flex flex-col w-full">
                        <span className="py-2 px-1 text-plant">Add Photo to your product</span>
                        <div className="flex justify-center items-center w-full bg-primary text-primary h-36 cursor-pointer rounded-xl">
                            <input type={'file'} className="hidden" />
                            <span className="text-plant text-sm bg-primary p-2 rounded"><MdAddAPhoto size={25} /></span>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                        <span className={"text-sm absolute left-3 z-10 " + (errName ? 'text-red' : 'text-plant')}>Product Name</span>
                        <input className={"w-full h-[58px] bg-primary focus:bg-black focus:scale-105 outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl "} placeholder="Enter a Product Name" value={name} onChange={(e) => setName(e.target.value)} onBlur={() => !name ? setErrName(true) : setErrName(false)} />
                    </div>
                    <Category value={category} onChange={(e) => handleChangeCategory(e)} />
                    <Brand value={brand} onChange={(e) => handleChangeBrand(e)} showValue={(e) => handleChangeShowBrand(e)} />
                    <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                        <span className={"text-sm absolute left-3 z-10 " + (errbarcode ? 'text-red' : 'text-plant')}>Product Barcode </span>
                        <input className="w-full h-[58px] bg-primary focus:bg-black focus:scale-105 outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a Barcode" value={barcode} onChange={(e) => { setbarcode(e.target.value) }} onBlur={() => { !barcode ? seterrbarcode(true) : seterrbarcode(false); console.log(errbarcode) }} />
                    </div>
                    <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                        <span className={"text-plant text-sm absolute left-3 z-10 "}>Measure Amount</span>
                        <div className=" w-full relative">
                            <input className="w-full h-[58px] bg-primary focus:bg-black focus:scale-105 outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter an Amount" />
                            <div className="h-[50%] absolute w-fit inset-y-0 right-4 translate-y-1/2 flex items-center pl-2 pr-10 cursor-pointer border border-color5 rounded text-plant italic">
                                <span>ml</span>
                                <IoMdArrowDropdown className="absolute inset-y-1 right-0 flex items-center cursor-pointer text-plant" size={25} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                        <span className={"text-sm absolute left-3 z-10 " + (errdescription ? 'text-red' : 'text-plant')}>Product Description</span>
                        <textarea className="w-full h-[82px] bg-primary focus:bg-black focus:scale-105 outline-none py-1 pt-5 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a Description" value={description} rows={3} style={{ resize: "none" }} onChange={(e) => setdescription(e.target.value)} onBlur={() => !description ? seterrdescription(true) : seterrdescription(false)} />
                    </div>
                </div>
                <div className="flex flex-col z-1">
                    <div className="relative flex flex-col px-4 shadow shadow-color5 py-2">
                        <span className="absolute w-fit bg-secondary text-xl text-color5 px-2 italic -top-4 right-6 font-bold">Inventory</span>
                        <Supplier value={supplier} onChange={(e) => handleChangeSupplier(e)} />
                        <div className="flex flex-row items-center justify-between gap-1 mt-2">
                            <div className="flex-1 w-full flex flex-col justify-between py-1 px-3 pr-3 rounded-xl bg-primary">
                                <span className="text-plant text-sm">Current Quantity</span>
                                <div className="flex items-center">
                                    <div className="flex items-center bg-primary">
                                        <input className="w-full bg-primary outline-none py-1 text-center placeholder:italic placeholder:text-sm" value={currentQty} onChange={(e) => currentQty > 0 ? setCurrentQty(e.target.value) : setCurrentQty(1)} />
                                        <div className="flex justify-end w-full px-1">
                                            <BiMinus className="text-color5 cursor-pointer rounded-[50%] border active:text-plant" size={20} onClick={() => currentQty > 1 ? setCurrentQty(currentQty - 1) : null} />
                                        </div>
                                        <div className="flex justify-end w-full px-1">
                                            <BiPlus className="text-color5 cursor-pointer rounded-[50%] border active:text-plant" size={20} onClick={() => setCurrentQty(currentQty + 1)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 w-full flex flex-col justify-between py-1 px-3 pr-3 rounded-xl bg-primary">
                                <span className="text-plant text-sm">Reorder Quantity</span>
                                <div className="flex items-center">
                                    <div className="flex items-center bg-primary">
                                        <input className="w-full bg-primary outline-none py-1 text-center pl-0 placeholder:italic placeholder:text-sm" value={reorderQty} onChange={(e) => reorderQty > 0 ? setReorderQty(e.target.value) : setReorderQty(1)} />
                                        <div className="flex justify-end w-full px-1">
                                            <BiMinus className="text-color5 cursor-pointer rounded-[50%] border active:text-plant" size={20} onClick={() => reorderQty > 1 ? setReorderQty(reorderQty - 1) : null} />
                                        </div>
                                        <div className="flex justify-end w-full px-1">
                                            <BiPlus className="text-color5 cursor-pointer rounded-[50%] border active:text-plant" size={20} onClick={() => setReorderQty(reorderQty + 1)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between gap-1 mt-2">
                            <div className="flex-1 w-full flex flex-col justify-between py-1 px-3 pr-3 rounded-xl bg-primary">
                                <span className="text-plant text-sm">Alert Quantity</span>
                                <div className="flex items-center">
                                    <div className="flex items-center bg-primary ">
                                        <input className=" w-full bg-primary outline-none py-1 text-center placeholder:italic placeholder:text-sm" value={alertQty} onChange={(e) => alertQty > 0 ? setAlertQty(e.target.value) : setAlertQty(1)} />
                                        <div className="flex justify-end w-full px-1">
                                            <BiMinus className="text-color5 cursor-pointer rounded-[50%] border active:text-plant" size={20} onClick={() => alertQty > 1 ? setAlertQty(alertQty - 1) : null} />
                                        </div>
                                        <div className="flex justify-end w-full px-1">
                                            <BiPlus className="text-color5 cursor-pointer rounded-[50%] border active:text-plant" size={20} onClick={() => setAlertQty(alertQty + 1)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 w-full flex flex-col justify-between py-1 px-6 pr-3 rounded-xl bg-transparen"></div>
                        </div>
                        <div className="flex flex-row items-center justify-between gap-1 mt-4">
                            <Toggle name='Get Alert Notification' enabled={alertnot} onClickValue={() => setalertnot(!alertnot)} />
                        </div>
                    </div>
                    <div className="flex flex-col px-4 shadow drop-shadow shadow-color5 py-2 mt-6">
                        <span className="absolute w-fit bg-secondary text-xl text-color5 px-2 italic -top-4 right-6 font-bold">Price</span>
                        <div className="flex flex-col justify-between py-1 mt-8 bg-transparent relative">
                            <span className="text-plant text-sm absolute left-3 z-10">Wholesale price</span>
                            <input className="w-full h-[58px] bg-primary focus:bg-black focus:scale-105 outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a wholesale price" value={wholesalepr} onChange={(e) => setwholesalepr(e.target.value)} onBlur={() => !wholesalepr ? setwholesalepr(0) : null} />
                        </div>
                        <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                            <span className="text-plant text-sm absolute left-3 z-10">Retail price</span>
                            <input className="w-full h-[58px] bg-primary focus:bg-black focus:scale-105 outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a retail price" value={retailpr} onChange={(e) => setretailpr(e.target.value)} onBlur={() => !retailpr ? setretailpr(0) : null} />
                        </div>
                        <div className="flex flex-row w-full gap-3">
                            <div className="w-full flex-grow flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                                <span className="text-plant text-sm absolute left-3 z-10">Special price</span>
                                <input className="w-full h-[58px] bg-primary focus:bg-black focus:scale-105 outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="Enter a special price" value={specialpr} onChange={(e) => setspecialpr(e.target.value)} onBlur={() => !specialpr ? setspecialpr(0) : null} />
                            </div>
                            <div className="flex flex-col justify-between py-1 mt-2 bg-transparent relative w-[55%]">
                                <input type={'number'} className="w-full h-[58px] bg-primary focus:bg-black focus:scale-105 outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" placeholder="" value={specialper} onChange={(e) => specialper <= 100 ? setspecialper(e.target.value) : setspecialper(100)} onBlur={() => !specialper ? setspecialper(0) : null} />
                                <span className="absolute right-3 translate-y-1/2 text-xl text-color5">%</span>
                            </div>
                        </div>
                        <div className="flex flex-row w-full gap-3">
                            <div className="w-full flex-grow flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                                <span className="text-plant text-sm absolute left-3 z-10">From</span>
                                <input type={'date'} className="w-full h-[58px] bg-primary focus:bg-black focus:scale-105 outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" onChange={(e) => setfromdate(e.target.value)} />
                            </div>
                            <div className="w-full flex-grow flex flex-col justify-between py-1 mt-2 bg-transparent relative">
                                <span className="text-plant text-sm absolute left-3 z-10">To</span>
                                <input type={'date'} className="w-full h-[58px] bg-primary focus:bg-black focus:scale-105 outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl" onChange={(e) => settodate(e.target.value)} />
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between gap-1 mt-4">
                            <Toggle name='Loyality Points' enabled={loyality} onClickValue={() => setloyality(!loyality)} />
                        </div>
                        <div className="flex flex-row items-center justify-between gap-1 mt-4">
                            <Toggle name='Commision' enabled={commision} onClickValue={() => setcommision(!commision)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className=" lg:p-4 w-full">
                <button className="bg-color4 text-primary w-full font-bold text-md p-2" onClick={() => addProduct()}>Save</button>
            </div>
        </div>
    )
}