import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from './View/Main';
import LoginPage from './Component/Login';
import Authentication from './Component/Authenticated'

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/Login' element={<LoginPage />} />
        <Route element={<Authentication />}>
          <Route exact path="/" element={<Main page='Home' btn='dashboard' />} />
          <Route exact path="/Home" element={<Main page='Home' btn='dashboard' />} />

          {/* Admin */}
          <Route exact path="/Store" element={<Main page='Store' btn='store' />} />
          <Route exact path="/Plan" element={<Main page='Plan' btn='plan' />} />
          <Route exact path="/PlanRequest" element={<Main page='PlanRequest' btn='request' />} />

          {/* Company */}
          <Route exact path='/Company' element={<Main page='Company' btn='dashboard' />} />
          <Route exact path='/CompanyUsers' element={<Main page='CompanyUsers' btn='companyusers' />} />
          <Route exact path='/CompanyCustomer' element={<Main page='CompanyCustomer' btn='companycustomer' />} />
          <Route exact path='/CompanyCustomerAdd' element={<Main page='CompanyCustomerAdd' btn='companycustomer' />} />
          <Route exact path='/CompanyCustomerDetails' element={<Main page='CompanyCustomerDetails' btn='companycustomer' />} />
          <Route exact path='/CompanyVendor' element={<Main page='CompanyVendor' btn='companyvendor' />} />
          <Route exact path='/CompanyProduct' element={<Main page='CompanyProduct' btn='companyproduct' />} />
          <Route exact path='/CompanyProductDetail' element={<Main page='CompanyProductDetail' btn='companyproductdetail' />} />
          <Route exact path='/CompanyProductNew' element={<Main page='CompanyProductNew' btn='companyproductnew' />} />
          <Route exact path='/CompanyServiceNew' element={<Main page='CompanyServiceNew' btn='companyservicenew' />} />
          <Route exact path='/CompanyPackageNew' element={<Main page='CompanyPackageNew' btn='companypackagenew' />} />
          <Route exact path='/CompanyMembershipNew' element={<Main page='CompanyMembershipNew' btn='companymembershipnew' />} />
        </Route>
      </Routes>
    </Router>
  );

}

export default App;
