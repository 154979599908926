import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"

export default function AdminSide(props) {
    const navigate = useNavigate()

    const [dashboardFocus, setDashboardFocus] = useState('')
    const [usersFocus, setUsersFocus] = useState('')
    const [storeFocus, setStoreFocus] = useState('')
    const [planFocus, setPlanFocus] = useState('')
    const [planRequestFocus, setPlanRequestFocus] = useState('')

    const iconClass = 'w-6 h-6 xl:mr-2 text-center align-center items-center '
    const buttonClass = 'flex xl:flex-row flex-col p-2 xl:justify-start justify-center xl:items-start items-center xl:px-5 my-1 shadow drop-shadow hover:bg-color1 hover:shadow-color1 rounded'
    const labelClass = 'xl:block xl:text-md text-xs p-1 whitespace-nowrap'

    useEffect(() => {
        const loadPage = async () => {
            await setDashboardFocus('')
            await setUsersFocus('')
            await setStoreFocus('')
            await setPlanFocus('')
            await setPlanRequestFocus('')

            switch (props.activeButton) {
                case 'dashboard': return setDashboardFocus('bg-color1')
                case 'users': return setUsersFocus('bg-color1')
                case 'store': return setStoreFocus('bg-color1')
                case 'plan': return setPlanFocus('bg-color1')
                case 'request': return setPlanRequestFocus('bg-color1')
                default: return
            }
        }
        loadPage()
            .catch(console.error);
    }, [props.activeButton])

    return (
        <div className={'lg:block hidden bg-secondary left-0 top-0 h-full w-full text-color2 '}>
            <label className="xl:block hidden flex-grow italic p-3 whitespace-nowrap font-bold">POS System Management</label>
            <div className="flex flex-col xl:px-2 xl:pt-5 pt-16">
                <button className={buttonClass + " " + dashboardFocus} onClick={() => navigate('/Home')}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={iconClass}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                        </svg>
                    </div>
                    <div>
                        <span className={labelClass}>Dashoboard</span>
                    </div>
                </button>
                <button className={buttonClass + " " + usersFocus}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={iconClass}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                    </svg>
                    <span className={labelClass}>Users</span>
                </button>
                <button className={buttonClass + " " + storeFocus} onClick={() => navigate('/Store')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={iconClass}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
                    </svg>
                    <span className={labelClass}>Store</span>
                </button>
                <button className={buttonClass + " " + planFocus} onClick={() => navigate('/Plan')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={iconClass}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42" />
                    </svg>
                    <span className={labelClass}>Plan</span>
                </button>
                <button className={buttonClass + " " + planRequestFocus} onClick={() => navigate('/PlanRequest')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={iconClass}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25" />
                    </svg>
                    <span className={labelClass}>Plan Request</span>
                </button>
            </div>
        </div >
    )
}