import { Transition } from '@headlessui/react'
import { useState, useEffect } from 'react'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { BiNote } from 'react-icons/bi'
import { HiXMark } from 'react-icons/hi2'
import { note, addnote, getnotes, deletenote } from '../../../Model/NoteModel'
import { format } from 'date-fns'

export default function Notes(props) {
    const [show, setShow] = useState(false)
    const activityDiv = 'flex flex-col items-center bg-primary lg:px-5 px-2 py-1 rounded-xl shadow drop-shadow'
    const iconStyle = 'p-6 rounded-3xl text-primary '

    const [singlenote, setsinglenote] = useState(note)
    const [notelist, setnotelist] = useState([])
    const [refresh, setrefresh] = useState(false)

    const id = props.id

    const addnewnote = async () => {
        addnote(singlenote, async (response) => {
            if (response.status) {
                await setsinglenote(note)
                await setrefresh(cur => { return true })
            } else {
                console.log(response)
            }
        })
    }

    const getnotelist = async () => {
        getnotes(id, (response) => {
            if (response.status) {
                setnotelist(response.response.data.data)
            } else {
                console.log(response)
            }
        })
    }

    const deletesinglenote = async (id) => {
        deletenote(id, (response) => {
            if (response.status) {
                if (response.response.data.status) {
                    setrefresh(() => { return true })
                } else {
                    console.log(response.response.data.err)
                }
            } else {
                console.log(response.err)
            }

        })
    }

    useEffect(() => {
        setShow(true)
        getnotelist()
    }, [])

    useEffect(() => {
        if (refresh) {
            getnotelist()
            setrefresh(() => { return false })
        }
    }, [refresh])

    return (
        <Transition
            className=""
            show={show}
        // enter="transition-all ease-in-out duration-500 delay-[200ms]"
        // enterFrom="opacity-0 translate-x-6"
        // enterTo="opacity-100 translate-x-0"
        // leave="transition-all ease-in-out duration-300"
        // leaveFrom="opacity-100 translate-x-6"
        // leaveTo="opacity-0 translate-x-0"
        >
            <div className={'flex flex-col'}>
                <div className="grid lg:grid-cols-4 grid-rows w-full gap-4 mt-2">
                    <div className={''}>
                        <div className={activityDiv}>
                            <div className='flex flex-row px-2 w-full lg:py-2 py-1'>
                                <div className='flex-grow flex flex-row items-center text-color5'>
                                    <BiNote size={30} />
                                    <span className='px-2'>New Note</span>
                                </div>
                            </div>
                            <div className='flex flex-row px-2 w-full lg:py-2 py-1'>
                                <textarea className='bg-secondary outline-none w-full px-1 rounded focus:border' rows={3} style={{ resize: "none" }} value={singlenote.body} onChange={(e) => setsinglenote({ ...singlenote, contactid: props.id, body: e.target.value })} />
                            </div>
                            <div className='flex flex-row justify-end px-2 w-full lg:py-2 py-1 cursor-pointer'>
                                <span className='bg-color4 text-primary font-bold rounded px-4 w-full text-center py-1 shadow drop-shadow shadow-sm shadow-color4' onClick={addnewnote}>Add</span>
                            </div>
                        </div>
                    </div>
                    <div className={'w-full lg:col-span-3 bg-primary p-3 rounded-xl overflow-x-auto'}>
                        <table className='w-full table-auto'>
                            <thead>
                                <tr className='text-left text-color5 border-b'>
                                    <th className='p-2 w-14'>#</th>
                                    <th className='p-2 w-28'>Date</th>
                                    <th className='p-2'>Note</th>
                                    <th className='p-2 w-24'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    notelist
                                        ? notelist.map(x => {
                                            return <tr key={x.id} className='text-left text-white border-b border-color5'>
                                                <td className='p-2 text-color5'>1</td>
                                                <td className='p-2 whitespace-nowrap text-sm'>{format(new Date(x.createddate), 'yyyy-MM-dd HH:mm:ss', '+2')}</td>
                                                <td className='p-2 min-w-[300px]'>{x.body}</td>
                                                <td className='px-8' onClick={() => deletesinglenote(x.id)}><HiXMark size={20} className='flex flex-row text-white rounded font-bold cursor-pointer bg-red' /></td>
                                            </tr>
                                        })
                                        : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Transition >
    )
}