import LeftSide from "../Component/Nav/LeftSide"
import Header from "../Component/Nav/Header"
import Home from "../Component/Admin/Dashboard/Home";
import Store from "../Component/Admin/Store/StoreView";
import Plan from "../Component/Admin/Plan/PlanView";
import PlanRequest from "../Component/Admin/Plan/PlanRequest";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom'

import Dashboard from "../Component/Company/Dashoboard";
import CompanyUsers from '../Component/Company/Users/UserView'

import CompanyContact from '../Component/Company/Contacts/ContactView'
import CompanyContactAdd from '../Component/Company/Contacts/ContactAdd'
import CompanyContactDetails from '../Component/Company/Contacts/ContactDetails'
import CompanyProduct from '../Component/Company/Products/ProductList'
import CompanyProductDetail from '../Component/Company/Products/ProductDetails'
import CompanyProductNew from '../Component/Company/Products/ProductNew'
import CompanyServiceNew from '../Component/Company/Products/ServiceNew'
import CompanyPackageNew from '../Component/Company/Products/PackageNew'
import CompanyMembershipNew from '../Component/Company/Products/MembershipNew'
import { useState } from "react";

export default function Main(props) {
    const navigate = useNavigate()
    const theme = 'theme-dark'
    const [cookie, setCookie] = useCookies();
    const [role, setRole] = useState('')

    const getComponent = () => {
        switch (props.page) {
            case 'Home': return <Home />
            case 'Store': return <Store />
            case 'Plan': return <Plan />
            case 'PlanRequest': return <PlanRequest />
            case 'Company': return <Dashboard />
            case 'CompanyUsers': return <CompanyUsers />
            case 'CompanyCustomer': return <CompanyContact contactType='customer' />
            case 'CompanyVendor': return <CompanyContact contactType='vendor' />
            case 'CompanyCustomerAdd': return <CompanyContactAdd contactType='customer' />
            case 'CompanyCustomerDetails': return <CompanyContactDetails contactType='customer' />
            case 'CompanyProduct': return <CompanyProduct />
            case 'CompanyProductDetail': return <CompanyProductDetail />
            case 'CompanyProductNew': return <CompanyProductNew />
            case 'CompanyServiceNew': return <CompanyServiceNew />
            case 'CompanyPackageNew': return <CompanyPackageNew />
            case 'CompanyMembershipNew': return <CompanyMembershipNew />
            default: return null
        }
    }

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };

    useEffect(() => {
        if (cookie.tkn === null || cookie.tkn === undefined) {
            setCookie('tkn', process.env.REACT_APP_TOKEN)
            clearCacheData()
        }
        if (cookie.tkn !== process.env.REACT_APP_TOKEN) {
            setCookie('tkn', process.env.REACT_APP_TOKEN, {
                expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
            })
            clearCacheData()
        }

        //Check if store is set or it is an Admin
        if (sessionStorage.getItem('sgstore')) {
            if (sessionStorage.getItem('sgstore') === '-1')
                setRole('admin')
            else
                setRole('company')
        }
        else {
            setRole('')
        }
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {
                role
                    ? <div className={theme + ' flex flex-row w-full h-full min-h-screen bg-primary z-1 m-0 p-0'}>
                        <div className="min-h-screen">
                            <LeftSide activeButton={props.btn} role={role} />
                        </div>
                        <div className="w-full">
                            <Header role={role} />
                            <div className="w-full p-3">
                                <div className="flex w-full lg:p-2 lg:px-0 m-0">
                                    <div className='flex flex-col w-full bg-primary text-color2 m-0 p-1 lg:p-10'>
                                        {getComponent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}