import axios from "axios"

export const note = {
    id: -1,
    contactid: -1,
    body: '',
    createdby: sessionStorage.getItem('sgid'),
    createddate: new Date()
}
const url = process.env.REACT_APP_BASEURL
const token = process.env.REACT_APP_TOKEN

const config = {
    headers: {
        authorization: `bear ${token}`
    }
}

export let getnotes = (contactid, callback) => {
    try {
        axios.post(`${url}getcontactnote`, { contactid: contactid }, config)
            .then((response) => {
                callback({
                    status: true,
                    response
                })
            })
            .catch((err) => {
                callback(err)
            })
    } catch (err) {
        callback(err)
    }
}

export let addnote = (note, callback) => {
    try {
        axios.post(`${url}addcontactnote`, {
            note
        }, config)
            .then(response => {
                callback(response)
            })
            .catch(err => {
                callback(err)
            })
    } catch (err) {
        callback(err)
    }
}

export let deletenote = (id, callback) => {
    try {
        axios.post(`${url}deletecontactnote`, {
            id
        }, config)
            .then(response => {
                callback({
                    status: true,
                    response
                })
            })
            .catch(err => {
                callback({
                    status: false,
                    err
                })
            })
    } catch (err) {
        callback({
            status: false,
            err
        })
    }
}