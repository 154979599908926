import { useLocation } from "react-router-dom"
import BackButton from "../../Admin/Controls/BackButton"
import Image from '../../../Assets/img/no-image.png'
import { useEffect, useState } from "react"
import Toggle from '../../utils/Toggle'
import { isMobile } from 'react-device-detect';

export default function ProductDetails(props) {
    const { state } = useLocation()
    const [menuClicked, setMenuClicked] = useState('details')
    const [showAction, setShowAction] = useState(false)

    const changeActionShow = () => {
        if (isMobile)
            setShowAction(!showAction)
    }

    useEffect(() => {
        if (isMobile)
            setShowAction(false)
        else
            setShowAction(true)
    }, [])

    return (
        <div className="flex flex-col w-full shadow shadow-xl lg:px-10 px-1 bg-secondary">
            {
                isMobile
                    ? <div className="">
                        <div className="fixed right-5 bottom-5 z-20 flex flex-col">
                            <div className={(showAction ? '' : 'hidden')}>
                                <div className="bg-transparent flex flex-row items-center justify-between">
                                    <span className="bg-primary flex-grow p-2 py-1 border border-color5 rounded-md mr-3 italic">Delete</span>
                                    <div className="flex items-center w-14 h-14 justify-center rounded-[50%] bg-color4 p-2 px-4 my-1 text-3xl" onClick={() => { }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-color1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="bg-transparent flex flex-row items-center justify-between">
                                    <span className="bg-primary flex-grow p-2 py-1 border border-color5 rounded-md mr-3 italic">Order Stock</span>
                                    <div className="flex items-center w-14 h-14 justify-center rounded-[50%] bg-color4 p-2 px-4 my-1 text-3xl" onClick={() => { }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-color3">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="bg-transparent flex flex-row items-center justify-between">
                                    <span className="bg-primary flex-grow p-2 py-1 border border-color5 rounded-md mr-3 italic">Update Stock</span>
                                    <div className="flex items-center w-14 h-14 justify-center rounded-[50%] bg-color4 p-2 px-4 my-1 text-3xl" onClick={() => { }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-color7">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="bg-transparent flex flex-row items-center justify-between">
                                    <span className="bg-primary flex-grow p-2 py-1 border border-color5 rounded-md mr-3 italic">Sell Item</span>
                                    <div className="flex items-center w-14 h-14 justify-center rounded-[50%] bg-color4 p-2 px-4 my-1 text-3xl" onClick={() => { }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-color6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className={"bg-transparent flex flex-row items-center justify-between "}>
                                <span className={"bg-primary flex-grow p-2 py-1 border border-color5 rounded-md mr-3 italic " + (showAction ? '' : 'hidden')}>Action</span>
                                <div className="flex items-center w-14 h-14 justify-center rounded-[50%] bg-plant p-2 px-4 my-1 text-3xl" onClick={() => { changeActionShow(!showAction) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-primary">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                                    </svg>

                                </div>
                            </div>
                        </div>
                        <div className={"fixed inset-0 bg-secondary w-full opacity-60 z-10 " + (showAction ? '' : 'hidden')} onClick={() => changeActionShow(!showAction)}></div>
                    </div>
                    : null
            }
            <div className='flex flex-row items-center py-2'>
                <BackButton />
                <div className="italic">
                    Dashboard * Product * {state.name}
                </div>
            </div>
            <div className='flex lg:flex-row flex-col items-center py-2 w-full justify-center'>
                <div className="grid lg:grid-cols-5 grid-rows gap-4 w-full">
                    <div className="flex lg:justify-end justify-center p-2">
                        <img src={state.photo ? state.photo : Image} className='object-fit  w-48 h-48' />
                    </div>
                    <div className="flex flex-col p-2 lg:pl-0 w-full lg:justify-start">
                        <span className="text-3xl lg:text-left text-center">{state.name}</span>
                        <span className="font-bold mt-8 lg:text-left text-center">
                            <span className="bg-gradient-to-r from-color4 to-plant text-primary p-2">{state.currentqty} in stock</span>
                            <span className="text-red text-sm italic px-1">{state.currentqty < state.alertqty ? 'Low Stock' : null}</span>
                        </span>
                    </div>
                    <div className="flex flex-col bg-primary justify-between items-center lg:p-10 py-1 shadow drop-shadow">
                        <span className="font-bold text-green text-xl">Retail price</span>
                        <span className="text-xl">{state.retailprice} L.L</span>
                    </div>
                    <div className="flex flex-col bg-primary justify-between items-center lg:p-10 py-1 shadow drop-shadow">
                        <span className="font-bold text-green text-xl">Supplier price</span>
                        <span className="text-xl">{state.wholesaleprice} L.L</span>
                    </div>
                    <div className="flex flex-col bg-primary justify-between items-center lg:p-10 py-1 shadow drop-shadow">
                        <span className="font-bold text-green text-xl">Category</span>
                        <span className="text-xl">{state.categoryname}</span>
                    </div>
                </div>
            </div>

            <div className='flex lg:flex-row flex-col items-center py-2 w-full justify-center'>
                <div className="flex lg:flex-row flex-col w-full">
                    <div className="lg:flex-grow flex flex-col w-full lg:order-1 order-2">
                        <div className="flex flex-row lg:justify-center justify-start items-center overflow-auto whitespace-nowrap">
                            <div className={'min-w-[200px] p-6 px-8 bg-primary cursor-pointer text-color5 font-bold text-center ' + (menuClicked === 'details' ? 'bg-color4 text-primary' : '')} onClick={() => setMenuClicked('details')}>Product details</div>
                            <div className={'min-w-[200px] p-6 px-8 bg-primary cursor-pointer text-color5 font-bold text-center ' + (menuClicked === 'sales' ? 'bg-color4 text-primary' : '')} onClick={() => setMenuClicked('sales')}>Sales</div>
                            <div className={'min-w-[200px] p-6 px-8 bg-primary cursor-pointer text-color5 font-bold text-center ' + (menuClicked === 'stock' ? 'bg-color4 text-primary' : '')} onClick={() => setMenuClicked('stock')}>Stock history</div>
                        </div>
                        <div className="lg:px-10 px-1 my-4">
                            <div className="grid lg:grid-cols-2 grid-rows lg:gap-4 gap-1">
                                <div className="bg-primary flex flex-row p-4 w-full rounded-xl">
                                    <span className="flex-1 text-center text-color4">Brand:</span>
                                    <span className={"flex-1 text-center " + (!state.brandname ? 'italic' : '')}>{state.brandname ? state.brandname : 'No brand assigned'}</span>
                                </div>
                                <div className="bg-primary flex flex-row p-4 rounded-xl">
                                    <span className="flex-1 text-center text-color4">Reorder Quantity:</span>
                                    <span className={"flex-1 text-center " + (!state.reorderqty ? 'italic' : '')}>{state.reorderqty ? state.reorderqty : '0'}</span>
                                </div>
                                <div className="bg-primary flex flex-row p-4 w-full rounded-xl">
                                    <span className="flex-1 text-center text-color4">Barcode:</span>
                                    <span className={"flex-1 text-center " + (!state.barcode ? 'italic' : '')}>{state.barcode ? state.barcode : 'No Barcode'}</span>
                                </div>
                                <div className="bg-primary flex flex-row p-4 rounded-xl">
                                    <span className="flex-1 text-center text-color4">Alert Quantity:</span>
                                    <span className={"flex-1 text-center " + (!state.alertqty ? 'italic' : '')}>{state.alertqty ? state.alertqty : '0'}</span>
                                </div>
                                <div className="bg-primary flex flex-row p-4 w-full rounded-xl">
                                    <span className="flex-1 text-center text-color4">Measure Amount:</span>
                                    <span className={"flex-1 text-center " + (!state.measureamount ? 'italic' : '')}>{state.measureamount ? state.measureamount : '0'}</span>
                                </div>
                                <div className="bg-primary flex flex-row p-4 rounded-xl">
                                    <span className="flex-1 text-center text-color4">Special Price:</span>
                                    <span className={"flex-1 text-center " + (!state.specialprice ? 'italic' : '')}>{state.specialprice ? state.specialprice : '0'}</span>
                                </div>
                                <div className="bg-primary flex flex-row p-4 w-full rounded-xl">
                                    <span className="flex-1 text-center text-color4">Supplier:</span>
                                    <span className={"flex-1 text-center " + (!state.supplierid ? 'italic' : '')}>{state.suppliername ? state.suppliername : 'No supplier selected'}</span>
                                </div>
                                <div className="bg-primary flex flex-row p-4 rounded-xl">
                                    <span className="flex-1 text-center text-color4">Special Price %:</span>
                                    <span className={"flex-1 text-center " + (!state.specialpriceper ? 'italic' : '')}>{state.specialpriceper ? state.specialpriceper : '0 %'}</span>
                                </div>
                                <div className="bg-primary flex flex-col p-4 rounded-xl">
                                    <span className="px-4 text-color4">Description:</span>
                                    <span className={"px-5 " + (!state.description ? 'italic' : '')}>{state.description ? state.description : '...'}</span>
                                </div>
                                <div className="bg-primary flex flex-col p-4">
                                    <span className="p-1 text-xl text-color5">Notification:</span>
                                    <div className="flex flex-row p-1 items-center">
                                        <span className="flex-grow">Loyality Points</span>
                                        <Toggle name='' enabled={state.loyality === 1 ? true : false} /></div>
                                    <div className="flex flex-row p-1 items-center">
                                        <span className="flex-grow">Get Alert Notifications</span>
                                        <Toggle name='' enabled={state.alertnotif === 1 ? true : false} /></div>
                                    <div className="flex flex-row p-1 items-center">
                                        <span className="flex-grow">Commission</span>
                                        <Toggle name='' enabled={state.commision === 1 ? true : false} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        !isMobile
                            ?
                            <div className="flex flex-col lg:items-center items-end lg:w-96 w-full gap-2 lg:order-2 order-1">
                                <div className="lg:w-full bg-primary p-5 font-bold text-red text-xl lg:mb-0 mb-2 cursor-pointer" onClick={() => changeActionShow()}>Action</div>
                                <div className="w-full py-1">
                                    <div className="flex justify-between items-center w-full p-4 bg-color8 text-primary font-bold">
                                        <span className="text-xl">Sell item</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-color6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                        </svg>
                                    </div>
                                    <div className="flex justify-between items-center w-full p-4 bg-color8 text-primary font-bold">
                                        <span className="text-xl">Update Stock</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-color7">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </div>
                                    <div className="flex justify-between items-center w-full p-4 bg-color8 text-primary font-bold">
                                        <span className="text-xl">Order Stock</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-color3">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                        </svg>
                                    </div>
                                    <div className="flex justify-between items-center w-full p-4 bg-color8 text-primary font-bold">
                                        <span className="text-xl">Delete</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-color1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </div>

                            </div>
                            : null
                    }
                </div>
            </div>

        </div>
    )
}