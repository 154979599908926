import { FaFileInvoiceDollar } from 'react-icons/fa'
import { GrEdit } from 'react-icons/gr'
import { AiOutlineSchedule } from 'react-icons/ai'
import { Transition } from '@headlessui/react'
import { useState, useEffect } from 'react'

export default function Activities(props) {
    const [show, setShow] = useState(false)
    const activityDiv = 'flex flex-row items-center bg-primary lg:px-5 px-2 py-5 rounded-xl shadow drop-shadow'
    const iconStyle = 'p-6 rounded-3xl text-primary '

    useEffect(() => {
        setShow(true)
    }, [])

    return (
        <Transition
            className=""
            show={show}
            enter="transition-all ease-in-out duration-500 delay-[200ms]"
            enterFrom="opacity-0 translate-x-6"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all ease-in-out duration-300"
            leaveFrom="opacity-100 translate-x-6"
            leaveTo="opacity-0 translate-x-0"
        >
            <div className="grid lg:grid-cols-2 grid-rows w-full gap-4 mt-1">
                <div className={activityDiv}>
                    <FaFileInvoiceDollar size={80} className={iconStyle + 'bg-plant'} />
                    <div className='flex flex-col px-3 w-full'>
                        <span className='text-sm text-color5'>New sale made @ 2023-02-18 17:30</span>
                        <span className='font-bold cursor-pointer text-green hover:underline'>Invoice # 15487961</span>
                        <span className='text-sm'>LBP 1,080,000</span>
                        <span className='text-color5 italic text-sm justify-end text-right'>By Micheal Jakson</span>
                    </div>
                </div>
                <div className={activityDiv}>
                    <GrEdit size={80} className={iconStyle + 'bg-green'} />
                    <div className='flex flex-col px-3 w-full'>
                        <span className='text-sm text-color5'>New modification made @ 2023-02-18 17:30</span>
                        <span className='text-sm cursor-pointer'>Name modified to </span>
                        <span className=''>Fadel Samhat</span>
                        <span className='text-color5 italic text-sm justify-end text-right'>By Micheal Jakson</span>
                    </div>
                </div>
                <div className={activityDiv}>
                    <AiOutlineSchedule size={80} className={iconStyle + 'bg-color4'} />
                    <div className='flex flex-col px-3 w-full'>
                        <span className='text-sm text-color5'>New apointment made @ 2023-02-18 17:30</span>
                        <span className='font-bold cursor-pointer text-green hover:underline'>Apointment # 15487961</span>
                        <span className='text-sm'>01-03-2023 @ 02:45 PM </span>
                        <span className='text-color5 italic text-sm justify-end text-right'>By Micheal Jakson</span>
                    </div>
                </div>
                <div className={activityDiv}>
                    <AiOutlineSchedule size={80} className={iconStyle + 'bg-color4'} />
                    <div className='flex flex-col px-3 w-full'>
                        <span className='text-sm text-color5'>New apointment made @ 2023-02-18 17:30</span>
                        <span className='font-bold cursor-pointer text-green hover:underline'>Apointment # 15487961</span>
                        <span className='text-sm'>01-03-2023 @ 02:45 PM </span>
                        <span className='text-color5 italic text-sm justify-end text-right'>By Micheal Jakson</span>
                    </div>
                </div>
                <div className={activityDiv}>
                    <GrEdit size={80} className={iconStyle + 'bg-green'} />
                    <div className='flex flex-col px-3 w-full'>
                        <span className='text-sm text-color5'>New modification made @ 2023-02-18 17:30</span>
                        <span className='text-sm cursor-pointer'>Address modified to </span>
                        <span className=''>Lebanon, Beirut, Rawshi street, Building 4561 Floor 4 </span>
                        <span className='text-color5 italic text-sm justify-end text-right'>By Micheal Jakson</span>
                    </div>
                </div>
            </div>
        </Transition>
    )
}