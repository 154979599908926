import { BsCheck, BsX } from 'react-icons/bs'
import { BiEdit } from 'react-icons/bi'

export default function PlanCard(props) {
    const iconClass = 'p-1 '

    return (
        <div className='flex flex-col w-full rounded bg-primary shadow drop-shadow shadow-primary shadow-md mb-10 lg:mb-5'>
            <div className='flex relative'>
                {/* <BiDotsVerticalRounded size={30} className='absolute right-0 m-5 hover:bg-color1 p-1 rounded' /> */}
                <div className='relative flex flex-row flex-grow p-3 justify-center '>
                    <div className='absolute -top-10 flex p-1 px-2 rounded mt-5 text-2xl bg-color1 '>
                        {props.name}
                    </div>
                </div>
            </div>
            <div className='italic text-xl p-2 m-3 text-center'>
                <span className='text-3xl font-bold'>{props.price}</span>/month
            </div>
            <div className='flex flex-col p-1 px-5 justify-center'>
                <div className='italic text-sm text-center mb-4'>
                    {props.user > 0 ? props.user : 'unlimited'} users
                </div>
                <div className='grid grid-cols-2 justify-between mb-3'>
                    <div className='flex italic text-sm'>
                        {props.account === 1 ? <BsCheck className={iconClass + 'text-green'} size={30} /> : <BsX className={iconClass + 'text-red'} size={30} />}
                        <label className='p-1'>Accounting</label>
                    </div>
                    <div className='flex italic text-sm'>
                        {props.pos === 1 ? <BsCheck className={iconClass + 'text-green'} size={30} /> : <BsX className={iconClass + 'text-red'} size={30} />}
                        <label className='p-1'>POS</label>
                    </div>
                    <div className='flex italic text-sm'>
                        {props.employee === 1 ? <BsCheck className={iconClass + 'text-green'} size={30} /> : <BsX className={iconClass + 'text-red'} size={30} />}
                        <label className='p-1'>Employee</label>
                    </div>
                    <div className='flex italic text-sm'>
                        {props.loyality === 1 ? <BsCheck className={iconClass + 'text-green'} size={30} /> : <BsX className={iconClass + 'text-red'} size={30} />}
                        <label className='p-1'>Loyality</label>
                    </div>
                </div>
                <div className='flex justify-end p-3'>
                    <button className='bg-green p-1 px-2 rounded'><BiEdit size={25} /></button>
                </div>
            </div>
        </div>
    )
}