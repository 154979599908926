import { CiLocationOn, CiLocationArrow1, CiTimer } from 'react-icons/ci'
import { Transition } from '@headlessui/react'
import { useState, useEffect } from 'react'

export default function Visits(props) {
    const [show, setShow] = useState(false)
    const activityDiv = 'flex flex-row w-full items-center justify-center bg-primary lg:px-5 px-2 py-5 rounded-xl shadow drop-shadow lg:whitespace-nowrap'
    const iconStyle = 'text-plant p-5 rounded-full bg-secondary '
    const iconNotificationStyle = 'text-red p-5 rounded-full bg-secondary'

    const data = props.data
    useEffect(() => {
        setShow(true)
    }, [])

    return (
        <Transition
            className=""
            show={show}
        // enter="transition-all ease-in-out duration-500 delay-[200ms]"
        // enterFrom="opacity-0 translate-x-6"
        // enterTo="opacity-100 translate-x-0"
        // leave="transition-all ease-in-out duration-300"
        // leaveFrom="opacity-100 translate-x-6"
        // leaveTo="opacity-0 translate-x-0"
        >
            <div className={'flex flex-col'}>
                <div className="grid lg:grid-cols-4 grid-rows w-full gap-4 mt-1">
                    <div className={activityDiv}>
                        <div className={iconStyle}>
                            <CiLocationOn size={25} className='' />
                        </div>
                        <div className='flex flex-col px-6 w-full'>
                            <span className='text-color5'>Last Visit</span>
                            <span className=''>{data.lastvisit ? data.lastvisit : <span className='italic text-sm text-color5'>No visit yet</span>}</span>
                        </div>
                    </div>
                    <div className={activityDiv}>
                        <div className={iconStyle}>
                            <CiLocationArrow1 size={25} />
                        </div>
                        <div className='flex flex-col px-6 w-full'>
                            <span className='text-color5'>Total Visit</span>
                            <span className=''>{data.totalvisit ? data.totalvisit : 0}</span>
                        </div>
                    </div>
                    <div className={activityDiv}>
                        <div className={iconStyle}>
                            <CiTimer size={25} />
                        </div>
                        <div className='flex flex-col px-6 w-full'>
                            <span className='text-color5'>Preferred Time</span>
                            <span className=''>{data.preferredtime ? data.preferredtime : <span className='italic text-sm text-color5'>No preferred time</span>}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    )
}