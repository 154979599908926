
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { AiOutlinePlus, AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import axios from 'axios'
import { format, addMonths } from 'date-fns'
import { useGlobalState } from "../../../Globals/GlobalStates"

export default function StoreNew(props) {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate()
    const url = process.env.REACT_APP_BASEURL
    const [refresh, setRefresh] = useGlobalState('refreshStore')
    const [emailExist, setEmailExist] = useState(false)

    const [passwordType, setPasswordType] = useState(true)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [currentplan, setCurrentPlan] = useState(1)
    const [expirydate, setExpiryDate] = useState('')
    const [active, setActive] = useState(1)
    const [createdby, setCreatedBy] = useState()
    const [createddate, setCreatedDate] = useState('')

    const [password, setPassword] = useState('')

    const addStore = async () => {
        await checkEmail()
        if (!emailExist) {
            await axios.post(url + 'AddStore', {
                name,
                email,
                phone,
                currentplan,
                expirydate,
                active,
                createdby,
                createddate,
                password
            })
                .then((response) => {
                    response = response.data
                    if (response.result) {
                        setRefresh(true)
                        setShowModal(false)
                    }
                })
        }
        else {

        }
    }

    const checkEmail = async () => {
        await axios.post(url + 'CheckUserExist', {
            email
        })
            .then(async (response) => {
                response = await response.data
                if (response.result) {
                    await setEmailExist(response.exist)
                }
            })
    }

    useEffect(() => {
        if (sessionStorage.getItem('sgid')) {
            let date = new Date()
            setCreatedBy(sessionStorage.getItem('sgid'))
            setCreatedDate(date)
            setExpiryDate(addMonths(date, 1))
        }
        else
            navigate('/Login')
    }, [])

    return (
        <>
            <button
                className='flex rounded float-right p-2 mb-5 bg-color4 justify-center items-center px-3text-[#ffff] hover:opacity-60 focus:border-blue ease-linear transition-all duration-150'
                type="button"
                onClick={() => setShowModal(true)}
            >
                <AiOutlinePlus size={20} />
            </button>
            {
                showModal ? (
                    <div className="top-[50px] fixed w-full z-10 ">
                        <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
                            <div className="relative flex-grow w-auto mt-20 mx-auto max-w-2xl rounded shadow drop-shadow-xl z-50 border border-secondary">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-color3 border border-secondary outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 rounded-t">
                                        <h3 className="text-xl font-semibold">
                                            New Company
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-sm leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent hover:opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative flex-auto">
                                        <div className="flex-grow px-5 bg-[#fff]] m-0 mt-4 mb-4 ml-2">
                                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 justify-between">
                                                <div>
                                                    <label className="flex p-2">Name</label>
                                                    <input className="flex w-full p-1 outline-none focus:border text-color5 bg-secondary focus:border-color1 rounded" value={name} onChange={(e) => setName(e.target.value)} />
                                                </div>
                                                <div>
                                                    <label className="flex items-center p-2">Email {emailExist ? <span className="text-sm italic text-red px-2">* Email exist</span> : ''}</label>
                                                    <input className="flex w-full p-1 outline-none focus:border text-color5 bg-secondary focus:border-color1 rounded" value={email} onChange={(e) => setEmail(e.target.value)} onBlur={() => { checkEmail() }} />
                                                </div>
                                                <div>
                                                    <label className="flex p-2">Phone</label>
                                                    <input className="flex w-full p-1 outline-none focus:border text-color5 bg-secondary focus:border-color1 rounded" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                                </div>
                                                <div className="relative">
                                                    <label className="flex p-2">Password</label>
                                                    <input type={passwordType ? 'password' : 'text'} className="flex w-full p-1 outline-none focus:border text-color5 bg-secondary focus:border-color1 rounded pr-8" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                    <div className="absolute right-2 top-12">
                                                        {
                                                            passwordType
                                                                ? <AiFillEye className="cursor-pointer" onMouseDown={() => setPasswordType(!passwordType)} />
                                                                : <AiFillEyeInvisible className="cursor-pointer" onMouseUp={() => setPasswordType(!passwordType)} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                    {/*footer*/}
                                    <div className="flex w-full px-3 py-2">
                                        <div className="flex flex-grow pl-8 py-2 text-color2 text-sm italic justify-start">
                                            * Free plan will be assigned on the creation
                                        </div>
                                        <div className="flex items-center justify-end">
                                            <button
                                                className="bg-secondary rounded font-bold uppercase text-sm px-3 py-3 outline-none focus:outline-none mr-1 mb-1"
                                                onClick={() => { setShowModal(false); }}
                                            >
                                                Close
                                            </button>
                                            <button
                                                className="bg-color4 rounded font-bold uppercase text-xs lg:text-sm px-3 py-3  outline-none focus:outline-none mr-1 mb-1"
                                                onClick={() => addStore()}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fixed opacity-60 inset-0 z-40 bg-secondary" onClick={() => setShowModal(false)}></div>
                        </div>
                    </div>
                ) : null
            }
        </>
    );
}