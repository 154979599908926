import { FaBirthdayCake, FaSms } from 'react-icons/fa'
import { TbGenderTransgender, TbPrompt } from 'react-icons/tb'
import { GoLocation } from 'react-icons/go'
import { MdEmail } from 'react-icons/md'
import Toggle from '../../utils/Toggle'
import { Transition } from '@headlessui/react'
import { useState, useEffect } from 'react'
import { format } from 'date-fns'

export default function PersonalInfo(props) {
    const [show, setShow] = useState(false)
    const data = props.data
    const activityDiv = 'flex flex-row w-full items-center justify-center bg-primary lg:px-5 px-2 py-10 rounded-xl shadow drop-shadow lg:whitespace-nowrap'
    const iconStyle = 'text-plant p-5 rounded-full bg-secondary '
    const iconNotificationStyle = 'absolute left-2 top-1/2 -translate-y-1/2 text-red p-5 rounded-full bg-secondary'

    const [emptydob, setemptydob] = useState(false)

    useEffect(() => {
        setShow(true)
        // console.log(data)
    }, [])

    return (
        <Transition
            className=""
            show={show}
        // enter="transition-all ease-in-out duration-500 delay-[200ms]"
        // enterFrom="opacity-0 translate-x-6"
        // enterTo="opacity-100 translate-x-0"
        // leave="transition-all ease-in-out duration-300"
        // leaveFrom="opacity-100 translate-x-6"
        // leaveTo="opacity-0 translate-x-0"
        >
            <div className='flex flex-col'>
                <div className="grid lg:grid-cols-4 grid-rows w-full gap-4 mt-1">
                    <div className={activityDiv}>
                        <div className={iconStyle}>
                            <FaBirthdayCake size={25} className='' />
                        </div>
                        <div className='flex flex-col px-6 w-full'>
                            <span className='text-color5'>Birthday</span>
                            <span className=''>
                                {
                                    data.dob != '0000-00-00'
                                        ? format(new Date(data.dob), 'yyyy-MM-dd')
                                        :
                                        !emptydob
                                            ? <span className='italic text-sm font-bold hover:underline text-color7'>Add DOB</span>
                                            : <div className="relative">
                                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                                                </div>
                                                <input type="date" class="border border-gray text-gray text-sm rounded-lg focus:ring-blue focus:border-blue block w-full pl-10 p-2.5 min-w-20 dark:bg-gray dark:border-gray dark:placeholder-gray dark:text-white dark:focus:ring-blue dark:focus:border-blue" placeholder="Select date" />
                                            </div>

                                }</span>
                        </div>
                    </div>
                    <div className={activityDiv}>
                        <div className={iconStyle}>
                            <TbGenderTransgender size={25} />
                        </div>
                        <div className='flex flex-col px-6 w-full'>
                            <span className='text-color5'>Gender</span>
                            <span className=''>{data.gender}</span>
                        </div>
                    </div>
                    <div className={activityDiv + ' lg:col-span-2'}>
                        <div className={iconStyle}>
                            <GoLocation size={25} />
                        </div>
                        <div className='flex flex-col px-6 w-full'>
                            <span className='text-color5'>Address</span>
                            <span className=''>{`${data.city ? data.city : ''} ${data.street ? ',' + data.street : ''} ${data.address ? ',' + data.address : ''}`}</span>
                        </div>
                    </div>

                </div>
                <span className='p-1 text-color5 font-bold text-lg mt-5'>Notifications</span>
                <div className='grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-rows w-full gap-4 mt-2 '>
                    <div className={activityDiv}>
                        <div className={iconNotificationStyle}>
                            <MdEmail size={20} className='' />
                        </div>
                        <div className='flex flex-col pl-16 w-full'>
                            <Toggle name='Email notification' enabled={data.emailnotification} />
                        </div>
                    </div>
                    <div className={activityDiv}>
                        <div className={iconNotificationStyle}>
                            <FaSms size={20} />
                        </div>
                        <div className='flex flex-col pl-16 w-full'>
                            <Toggle name='Text marketing' enabled={data.textmarketingnotification} />
                        </div>
                    </div>
                    <div className={activityDiv}>
                        <div className={iconNotificationStyle}>
                            <TbPrompt size={20} />
                        </div>
                        <div className='flex flex-col pl-16 w-full '>
                            <Toggle name='Email marketing' enabled={data.emailmarketingnotification} />
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    )
}