import { useState } from "react";

export default function Toggle(props) {
    // const [enabled, setEnabled] = useState(false);

    return (
        // <div className="relative flex flex-col items-center justify-center min-h-screen overflow-hidden">
        <div className="flex text-sm ">
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
                <input type="checkbox" className="sr-only peer border" checked={props.enabled} readOnly />
                {/* <div onClick={() => { setEnabled(!enabled); console.log(enabled) }} */}
                <div onClick={props.onClickValue} className="w-12 h-6 bg-color5 rounded-full peer peer-focus:ring-green peer-checked:after:translate-x-full peer-checked:after:border-color2 after:content-[''] after:absolute after:top-1 after:left-[2px] after:bg-color2 after:border after:border-color5 after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-red"></div>
                <span className="w-full nowhitespace ml-2 font-medium">
                    {props.name}
                </span>
            </label>
        </div>
        // </div>
    );
}