import { useGlobalState } from "../../Globals/Values"
import AdminMobileHead from "./AdminMobileHead"
import { HiOutlineMenu } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import { useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import CompanyMobileHead from "./CompanyMobileHead"

export default function Header(props) {
    const navigate = useNavigate()

    const [menuIcon, setMenuIcon] = useGlobalState('menuIcon')
    const [leftPanel, setLeftPanel] = useGlobalState('leftPanel')
    const [showMobile, setShowMobile] = useGlobalState('showMobile')

    const showMobileMenu = () => {
        if (showMobile) {
            setShowMobile(false)
            setMenuIcon(<HiOutlineMenu size={25} />)
        }
        else {
            setShowMobile(true)
            setMenuIcon(<AiOutlineClose size={25} />)
        }
    }

    const showMobileLeftMenu = () => {
        if (leftPanel) {
            setLeftPanel(false)
        }
        else {
            setLeftPanel(true)
        }
    }

    useEffect(() => {

    }, [])

    return (
        <div className={'flex bg-secondary w-full p-3 text-color2'}>
            <div className="flex-grow">
                <button className="lg:hidden block p-2 rounded" onClick={() => showMobileMenu()}>
                    {menuIcon}
                </button>
                <button className="lg:block hidden block p-2 rounded" onClick={() => showMobileLeftMenu()}>
                    {menuIcon}
                </button>
            </div>
            <button className="float-right p-2 hover:bg-color1 rounded">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" onClick={() => { sessionStorage.clear(); window.location.reload(false); }}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
            </button>
            <button className="float-right p-2 hover:bg-color1 rounded">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                </svg>
            </button>

            {/* Mobile Menu */}
            {
                showMobile
                    ? (
                        props.role === 'admin'
                            ? <AdminMobileHead />
                            : props.role === 'company'
                                ? <CompanyMobileHead />
                                : null
                    )
                    : null
            }
        </div>
    )
}