import { FaFileInvoiceDollar, FaDollarSign } from 'react-icons/fa'
import { HiOutlineBellAlert } from 'react-icons/hi2'

export default function Cards(props) {
    const getIcon = (iconName) => {
        switch (iconName) {
            case 'invoice': return <FaFileInvoiceDollar size={60} className='m-5' />
            case 'profit': return <FaDollarSign size={60} className='m-5' />
            case 'alert': return <HiOutlineBellAlert size={60} className='m-5' />
            default: return
        }
    }

    return (
        <div className='lg:p-1'>
            <div className='flex w-full h-36 rounded bg-color1 mb-2'>
                <div className='flex flex-col p-3'>
                    <div className='flex p-auto m-auto'>
                        {getIcon(props.icon)}
                    </div>
                </div>
                <div className='flex flex-grow flex-col p-3 px-10 justify-end items-end'>
                    <div className='flex flex-col'>
                        <div className='flex text-right text-2xl justify-end w-full'>
                            {props.title}
                        </div>
                        <div className='flex text-3xl font-bold justify-end w-full'>
                            {props.footer}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}