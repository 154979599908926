import { BiDotsVerticalRounded } from 'react-icons/bi'
import PersonImage from '../../../Assets/img/user.png'

export default function UserCard(props) {
    const iconClass = 'p-1 '

    return (
        <div className='flex flex-col w-full h-56 rounded bg-primary shadow drop-shadow shadow-primary mb-1 lg:mb-5'>
            <div className='flex relative'>
                <BiDotsVerticalRounded size={30} className='absolute right-0 m-2 hover:bg-color1 p-1 rounded' />
                <div className='flex flex-row flex-grow p-3 justify-center '>
                    <div className='flex p-auto mt-5'>
                        <div className='rounded-full w-28 h-28 bg-red flex justify-center items-center text-2xl'>MS</div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col p-5 justify-start'>
                <div className='flex flex-row text-md'>
                    <div className='flex justify-start w-full'>
                        {props.name}
                    </div>
                    <div className='flex font-bold justify-end w-full italic'>
                        {props.userRole}
                    </div>
                </div>
            </div>
        </div>
    )
}