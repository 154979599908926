import { useEffect, useState } from "react"
import { IoMdArrowDropdown, IoMdAddCircleOutline } from 'react-icons/io'
import { BiSearch } from 'react-icons/bi'
import axios from 'axios'

export default function Supplier(props) {
    const url = process.env.REACT_APP_BASEURL
    const token = process.env.REACT_APP_TOKEN
    const [showModal, setShowModal] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [supplierList, setsupplierList] = useState([])
    const [suppliername, setsupplierName] = useState('')
    const [suppliernameerr, setsupplierNameErr] = useState('')
    const [refresh, setRefresh] = useState(true)
    const [searchKey, setSearchKey] = useState('')

    const handleChangesupplier = async (event) => {
        await props.onChange(event)
        await setShowModal(false)
    }

    const addsupplier = async () => {
        try {
            setsupplierNameErr('')
            if (suppliername) {
                axios.post(url + 'addsupplier',
                    {
                        suppliername,
                        storeid: sessionStorage.getItem('sgstore'),
                        createdby: sessionStorage.getItem('sgid'),
                        createddate: new Date()
                    },
                    {
                        headers: {
                            authorization: `bear ${token}`
                        }
                    })
                    .then(async (response) => {
                        if (response.status === 200 && response.data.result) {
                            await setRefresh(true)
                            await setsupplierName('')
                            await setShowAdd(false)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                setsupplierNameErr('* Required')
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getsuppliers = async () => {
        try {
            axios.post(url + 'getsuppliers', { storeid: sessionStorage.getItem('sgstore') }, {
                headers: {
                    authorization: `bear ${token}`
                }
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        await setsupplierList(response.data.result)
                        await setRefresh(false)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (refresh) {
            getsuppliers()
        }
    }, [refresh])

    return (
        <div className="">
            <div className="flex flex-col justify-between py-1 mt-2 bg-transparent cursor-pointer relative " onClick={() => setShowModal(!showModal)}>
                <span className="text-plant text-sm absolute left-3">Product supplier</span>
                <input className="w-full h-[58px] bg-primary focus:bg-black outline-none py-1 px-4 placeholder:italic placeholder:text-sm rounded-xl cursor-pointer " placeholder="Choose a supplier" value={props.value.name} readOnly />
                <IoMdArrowDropdown className="absolute inset-y-0 right-5 translate-y-1/2 flex items-center cursor-pointer text-plant" size={33} />
            </div>
            {
                showModal ? (
                    <div className="fixed w-full z-20">
                        <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
                            <div className="relative flex-grow w-auto mt-20 lg:mx-auto mx-0 max-w-xl rounded shadow drop-shadow-xl z-50 border border-primary">
                                {/*content*/}
                                <div className="border-none rounded-lg shadow-lg relative flex flex-col w-full bg-primary border border-primary outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 rounded-t">
                                        <h3 className="text-xl font-semibold text-color5">
                                            Select a supplier
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-color5 float-right text-sm leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent hover:opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="flex-auto px-5">
                                        <div className='relative py-2'>
                                            <input className="pl-10 bg-primary w-full p-2 placeholder:italic rounded focus:border focus:border-color5 outline-none" placeholder='Search a supplier' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                            <div className='absolute top-1/2 -translate-y-1/2 left-2'>
                                                <BiSearch size={25} />
                                            </div>
                                        </div>
                                        <div className='text-color4 px-5 cursor-pointer shadow shadow-color4 py-2'>
                                            <div className='relative py-2 flex' onClick={() => setShowAdd(!showAdd)}>
                                                <div className='flex-grow'>
                                                    <span>Add new supplier</span>
                                                    <span className="text-md text-red italic px-1">{suppliernameerr}</span>
                                                </div>
                                                <div className=''>
                                                    <IoMdAddCircleOutline size={25} />
                                                </div>
                                            </div>
                                            {
                                                showAdd
                                                    ? <div className=''>
                                                        <input className="bg-primary w-full p-2 placeholder:italic rounded focus:border focus:border-color5 outline-none text-color5" placeholder='New supplier name' value={suppliername} onChange={(e) => { setsupplierNameErr(''); setsupplierName(e.target.value) }} />
                                                        <div className='flex justify-end p-2'>
                                                            <button className='w-full text-primary p-1 px-4 bg-color4 font-bold' onClick={() => addsupplier()}>Save</button>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div className='flex flex-col p-2'>
                                            {
                                                supplierList && supplierList.length > 0
                                                    ? supplierList.map((d, index) => {
                                                        return <div key={d.id} className={'p-3 border-b border-color5 cursor-pointer ' + (d.name.toLowerCase().includes(searchKey.toLowerCase()) ? '' : 'hidden')} onClick={() => handleChangesupplier(d)}>
                                                            <span className="font-bold text-color5 ">{d.name}</span>
                                                        </div>
                                                    })
                                                    : <div className='text-center text-sm text-color5 italic'>No suppliers to show</div>
                                            }
                                        </div>
                                    </div >
                                    {/*footer*/}
                                    <div className="flex w-full px-3 py-2">
                                        {/* <div className="flex lg:flex-row flex-col w-full text-center items-center gap-2">
                                            <div className="w-full lg:w-24 bg-primary lg:order-1 order-2 rounded font-bold uppercase text-sm px-3 py-3 outline-none focus:outline-none mb-1 cursor-pointer"
                                                onClick={() => { setShowModal(false); }}
                                            >
                                                Close
                                            </div>
                                            <div className="w-full flex-grow bg-color4 lg:order-2 order-1 rounded font-bold uppercase text-xs lg:text-sm px-3 py-3 outline-none focus:outline-none mb-1 cursor-pointer"
                                                onClick={() => { }}
                                            >
                                                Save
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="fixed opacity-60 inset-0 z-40 bg-primary" onClick={() => setShowModal(false)}></div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}