import { useEffect, useState } from "react"
import StoreCard from "./StoreCard"
import StoreNew from "./StoreNew"
import axios from "axios"
import { useGlobalState } from "../../../Globals/GlobalStates"

export default function StoreView(props) {
    const url = process.env.REACT_APP_BASEURL
    const [storeList, setStoreList] = useState([])
    const [refresh, setRefresh] = useGlobalState('refreshStore')

    const getStores = async () => {
        await axios.get(url + 'GetActiveStores')
            .then((response) => {
                response = response.data
                if (response.result) {
                    setStoreList(response.data)
                }
            })
    }

    useEffect(() => {
        if (refresh) {
            setRefresh(false)
            getStores()
        }
    }, [refresh])

    useEffect(() => {
        setRefresh(true)
    }, [])

    return (
        <div className=''>
            <div className="justify-end m-1">
                <StoreNew />
            </div>

            <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 xs:grid-cols-3 grid-cols-1 gap-4 justify-between w-full'>
                {
                    storeList
                        ? storeList.map((d, index) => {
                            return <StoreCard key={index} id={d.id} img='' name={d.name} plan={d.planname} planid={d.planid} />
                        })
                        : <span>No data to show</span>
                }
            </div>

        </div>
    )
}