import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);



export default function ProfitChart() {
    const options = {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Profit',
            },
        },
    };
    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: '2023',
                data: [65, 59, 80, 81, 56, 55, 40],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 0.5)',
                ],
                borderWidth: 1,
            },
            {
                label: '2022',
                data: [53, 40, 90, 75, 61, 52, 30],
                backgroundColor: [
                    'rgba(53, 162, 235, 0.5)',
                ],
                borderColor: [
                    'rgba(53, 162, 235, 0.5)',
                ],
                borderWidth: 1,
            },

        ],
    };

    return (
        <div className='w-full'>
            <Bar data={data} options={options} />
        </div>
    );
}