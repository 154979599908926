import { createGlobalState } from "react-hooks-global-state";
import { HiOutlineMenu } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'

const { setGlobalState, useGlobalState } = createGlobalState({
    leftPanel: true,
    showMobile: false,
    menuIcon: <HiOutlineMenu size={25} />,
});

export { useGlobalState, setGlobalState };