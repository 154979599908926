import Cards from '././Cards';
import ProfitChart from '././ProfitChart';

export default function Home() {

    return (
        <div className=''>
            <div className='grid lg:grid-cols-3 grid-rows grap-3 w-full flex-col lg:flex-row'>
                <Cards title="Today's Invoices" footer='25' icon='invoice' />
                <Cards title="Today's Profit" footer='7,500' icon='profit' />
                <Cards title="Alert" footer='5' icon='alert' />
            </div>
            <div className='flex lg:flex-row m-0 lg:p-10 lg:h-[500px] h-[250px] bg-secondary text-color2'>
                <ProfitChart />
            </div>
        </div>
    );
}