import axios from "axios"

export const cnt = {
    type: -1,
    name: '',
    dob: '',
    phone: '',
    gender: 'Unspecific',
    city: '',
    street: '',
    address: '',
    email: '',
    emailnotification: false,
    textmarketingnotification: false,
    emailmarketingnotification: false,
    clientSource: 'Walkin',
    staff: { id: -1, name: '' },
    note: [{ index: 0, val: '', createdby: sessionStorage.getItem('sgid'), createddate: new Date() }],
    store: sessionStorage.getItem('sgstore'),
    createdby: sessionStorage.getItem('sgid'),
    createddate: new Date()
}
const url = process.env.REACT_APP_BASEURL
const token = process.env.REACT_APP_TOKEN

const config = {
    headers: {
        authorization: `bear ${token}`
    }
}

export let getcontacts = (callback) => {
    try {
        cnt.store = sessionStorage.getItem('sgstore')
        axios.post(`${url}getcontact`, { store: cnt.store }, config)
            .then((response) => {
                callback(response)
            })
            .catch((err) => {
                return err
            })
    } catch (err) {
        return err
    }
}

export let addcontact = (contact, callback) => {
    try {
        axios.post(`${url}addcontact`, {
            contact
        }, config)
            .then(response => {
                callback(response)
            })
            .catch(err => {
                callback(err)
            })
    } catch (err) {
        callback(err)
    }
}