import { useGlobalState } from "../../Globals/Values"
import AdminSide from "./AdminSide"
import CompanySide from "./CompanySide"

export default function LeftSide(props) {
    const [leftPanel, setLeftPanel] = useGlobalState('leftPanel')

    return (
        <>
            {
                leftPanel
                    ? (props.role === 'admin'
                        ? <AdminSide activeButton={props.activeButton} />
                        : props.role === 'company'
                            ? <CompanySide activeButton={props.activeButton} />
                            : props.role === 'user'
                                ? <></>
                                : props.role === 'customer'
                                    ? <></>
                                    : null
                    )
                    : null
            }
        </>
    )
}