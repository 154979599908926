import ReactStars from 'react-stars'
import { useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { BsFillEmojiAngryFill, BsFillEmojiFrownFill, BsEmojiExpressionlessFill, BsEmojiSmileFill, BsEmojiHeartEyesFill } from 'react-icons/bs'

export default function Review(props) {
    const [show, setShow] = useState(false)
    const activityDiv = 'grid lg:grid-cols-5 grid-row bg-primary lg:px-5 px-2 py-1 rounded-xl shadow drop-shadow'
    const iconStyle = 'p-6 rounded-3xl text-primary '

    useEffect(() => {
        setShow(true)
    }, [])

    return (
        <Transition
            className=""
            show={show}
        // enter="transition-all ease-in-out duration-500 delay-[200ms]"
        // enterFrom="opacity-0 translate-x-6"
        // enterTo="opacity-100 translate-x-0"
        // leave="transition-all ease-in-out duration-300"
        // leaveFrom="opacity-100 translate-x-6"
        // leaveTo="opacity-0 translate-x-0"
        >
            <div className={activityDiv}>
                <div className='w-full'>
                    <div className='w-full grid grid-rows-5 p-5'>
                        <div className='w-full flex flex-row items-center justify-center text-color4 my-1 py-1 px-3 bg-secondary'>
                            <div className='flex-grow'>
                                <BsFillEmojiAngryFill className='flex-grow' size={30} />
                            </div>
                            <span className='font-bold'>0</span>
                        </div>
                        <div className='w-full flex flex-row items-center justify-center text-color4 my-1 py-1 px-3 bg-secondary'>
                            <div className='flex-grow'>
                                <BsFillEmojiFrownFill className='flex-grow' size={30} />
                            </div>
                            <span className='font-bold'>1</span>
                        </div>
                        <div className='w-full flex flex-row items-center justify-center text-color4 my-1 py-1 px-3 bg-secondary'>
                            <div className='flex-grow'>
                                <BsEmojiExpressionlessFill className='flex-grow' size={30} />
                            </div>
                            <span className='font-bold'>3</span>
                        </div>
                        <div className='w-full flex flex-row items-center justify-center text-color4 my-1 py-1 px-3 bg-secondary'>
                            <div className='flex-grow'>
                                <BsEmojiSmileFill className='flex-grow' size={30} />
                            </div>
                            <span className='font-bold'>0</span>
                        </div>
                        <div className='w-full flex flex-row items-center justify-center text-color4 my-1 py-1 px-3 bg-secondary'>
                            <div className='flex-grow'>
                                <BsEmojiHeartEyesFill className='flex-grow' size={30} />
                            </div>
                            <span className='font-bold'>12</span>
                        </div>
                    </div>
                </div>
                <div className='lg:col-span-4'>
                    <div className='grid grid-cols-1 gap-1 py-1 px-2 lg:my-6 my-2 bg-secondary w-full col-span-4 rounded-xl'>
                        <div className='w-full flex flex-row items-center p-2 relative'>
                            <div className='flex-shrink'>
                                <span className='w-18 h-18 flex items-center justify-center bg-green p-5 rounded-[50%] text-2xl font-["Kristen_ITC"]'>MS</span>
                            </div>
                            <div className='flex-grow px-5'>
                                Test Test Test Test Test Test
                            </div>

                            <div className='absolute right-2 top-2 italic text-color5'>
                                <BsEmojiHeartEyesFill className='flex-grow text-color4' size={30} />
                            </div>
                            <div className='absolute right-2 bottom-2 italic text-color5'>
                                2023-01-25
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 gap-3 py-1 px-2 lg:my-6 my-2 bg-secondary w-full col-span-4 rounded-xl'>
                        <div className='w-full flex flex-row items-center p-2 relative'>
                            <div className='flex-shrink'>
                                <span className='w-18 h-18 flex items-center justify-center bg-green p-5 rounded-[50%] text-2xl font-["Kristen_ITC"]'>MS</span>
                            </div>
                            <div className='flex-grow px-5'>
                                Test Test Test Test Test Test
                            </div>

                            <div className='absolute right-2 top-2 italic text-color5'>
                                <BsEmojiHeartEyesFill className='flex-grow text-color4' size={30} />
                            </div>
                            <div className='absolute right-2 bottom-2 italic text-color5'>
                                2023-01-25
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 gap-3 py-1 px-2 lg:my-6 my-2 bg-secondary w-full col-span-4 rounded-xl'>
                        <div className='w-full flex flex-row items-center p-2 relative'>
                            <div className='flex-shrink'>
                                <span className='w-18 h-18 flex items-center justify-center bg-green p-5 rounded-[50%] text-2xl font-["Kristen_ITC"]'>MS</span>
                            </div>
                            <div className='flex-grow px-5'>
                                Test Test Test Test Test Test
                            </div>

                            <div className='absolute right-2 top-2 italic text-color5'>
                                <BsEmojiHeartEyesFill className='flex-grow text-color4' size={30} />
                            </div>
                            <div className='absolute right-2 bottom-2 italic text-color5'>
                                2023-01-25
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    )
}