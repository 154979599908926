import { Transition } from '@headlessui/react'
import { useState, useEffect } from 'react'
import { FaFileInvoiceDollar } from 'react-icons/fa'


export default function Appointments(props) {
    const [show, setShow] = useState(false)
    const activityDiv = 'flex flex-col items-center bg-primary lg:px-5 px-2 py-1 rounded-xl shadow drop-shadow'
    const iconStyle = 'p-6 rounded-3xl text-primary '

    useEffect(() => {
        setShow(true)
    }, [])

    return (
        <Transition
            className=""
            show={show}
        // enter="transition-all ease-in-out duration-500 delay-[200ms]"
        // enterFrom="opacity-0 translate-x-6"
        // enterTo="opacity-100 translate-x-0"
        // leave="transition-all ease-in-out duration-300"
        // leaveFrom="opacity-100 translate-x-6"
        // leaveTo="opacity-0 translate-x-0"
        >
            <div className={'flex flex-col'}>
                <div className="grid lg:grid-cols-4 grid-rows w-full gap-4 mt-2">
                    <div className={activityDiv}>
                        <div className='flex flex-row px-6 w-full lg:py-4 py-2'>
                            <span className='flex-grow text-color5'>Total Appointments</span>
                            <span className='justify-end'>29</span>
                        </div>
                        <div className='flex flex-row px-6 w-full lg:py-4 py-2'>
                            <span className='flex-grow text-color5'>Completed</span>
                            <span className=''>15</span>
                        </div>
                        <div className='flex flex-row px-6 w-full lg:py-4 py-2'>
                            <span className='flex-grow text-color5'>Canceled</span>
                            <span className=''>15</span>
                        </div>
                        <div className='flex flex-row px-6 w-full lg:py-4 py-2'>
                            <span className='flex-grow text-color5'>No-show</span>
                            <span className=''>15</span>
                        </div>
                    </div>
                    <div className={'lg:col-span-3'}>
                        <div className='grid lg:grid-cols-2 grid-row gap-3'>
                            <div className={'flex flex-row items-center bg-primary lg:px-3 px-2 py-2 rounded-xl shadow drop-shadow relative'}>
                                <div className='flex flex-col bg-secondary border border-1 border-plant text-plant text-center text-sm font-bold px-4 rounded shadow drop-shadow shadow-plant'>
                                    <span className='text-lg'>05</span>
                                    <span className='-mt-2 text-lg opacity-80'>Dec</span>
                                    <span className='opacity-80'>2023</span>
                                </div>
                                <div className='flex flex-col px-3 w-full'>
                                    <span className='font-bold cursor-pointer text-green hover:underline'>Appointment # 15487961</span>
                                    <span className='text-sm'>LBP 1,080,000</span>
                                    <span className='text-sm italic text-color5'>Lionel Messi</span>
                                    <span className=' absolute right-4 -bottom-2 bg-green text-primary font-bold px-1 rounded italic text-sm justify-end text-right'>Completed</span>
                                </div>
                            </div>
                            <div className={'flex flex-row items-center bg-primary lg:px-3 px-2 py-2 rounded-xl shadow drop-shadow relative'}>
                                <div className='flex flex-col bg-secondary border border-1 border-plant text-plant text-center text-sm font-bold px-4 rounded shadow drop-shadow shadow-plant'>
                                    <span className='text-lg'>05</span>
                                    <span className='-mt-2 text-lg opacity-80'>Dec</span>
                                    <span className='opacity-80'>2023</span>
                                </div>
                                <div className='flex flex-col px-3 w-full'>
                                    <span className='font-bold cursor-pointer text-green hover:underline'>Appointment # 15487961</span>
                                    <span className='text-sm'>LBP 1,080,000</span>
                                    <span className='text-sm italic text-color5'>Lionel Messi</span>
                                    <span className=' absolute right-4 -bottom-2 bg-red text-primary font-bold px-1 rounded italic text-sm justify-end text-right'>No-show</span>
                                </div>
                            </div>
                            <div className={'flex flex-row items-center bg-primary lg:px-3 px-2 py-2 rounded-xl shadow drop-shadow relative'}>
                                <div className='flex flex-col bg-secondary border border-1 border-plant text-plant text-center text-sm font-bold px-4 rounded shadow drop-shadow shadow-plant'>
                                    <span className='text-lg'>05</span>
                                    <span className='-mt-2 text-lg opacity-80'>Dec</span>
                                    <span className='opacity-80'>2023</span>
                                </div>
                                <div className='flex flex-col px-3 w-full'>
                                    <span className='font-bold cursor-pointer text-green hover:underline'>Appointment # 15487961</span>
                                    <span className='text-sm'>LBP 1,080,000</span>
                                    <span className='text-sm italic text-color5'>Lionel Messi</span>
                                    <span className=' absolute right-4 -bottom-2 bg-color4 text-primary font-bold px-1 rounded italic text-sm justify-end text-right'>Canceled</span>
                                </div>
                            </div>
                            <div className={'flex flex-row items-center bg-primary lg:px-3 px-2 py-2 rounded-xl shadow drop-shadow relative'}>
                                <div className='flex flex-col bg-secondary border border-1 border-plant text-plant text-center text-sm font-bold px-4 rounded shadow drop-shadow shadow-plant'>
                                    <span className='text-lg'>05</span>
                                    <span className='-mt-2 text-lg opacity-80'>Dec</span>
                                    <span className='opacity-80'>2023</span>
                                </div>
                                <div className='flex flex-col px-3 w-full'>
                                    <span className='font-bold cursor-pointer text-green hover:underline'>Appointment # 15487961</span>
                                    <span className='text-sm'>LBP 1,080,000</span>
                                    <span className='text-sm italic text-color5'>Lionel Messi</span>
                                    <span className=' absolute right-4 -bottom-2 bg-color7 text-primary font-bold px-1 rounded italic text-sm justify-end text-right'>New</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    )
}